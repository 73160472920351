import { Button, IconButton, useTheme } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { useRef } from 'react';
import { useSnackbar } from 'src/components/snackbar';

interface FileUploadProps {
  onFileSelect: (files: File[]) => void;
  disabled?: boolean;
  darkMode?: boolean;
  uploadType?: string;
  uploadLabel?: string;
}

export default function FileUpload({
  onFileSelect,
  disabled,
  darkMode,
  uploadType,
  uploadLabel
}: FileUploadProps) {
  const theme = useTheme();
  const allowedFileTypes = [
    'text/plain',
    'text/markdown',
    'text/html',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/pdf',
  ];
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const invalidFiles = files.filter((file) => {
      if (file.size > 20 * 1024 * 1024) {
        enqueueSnackbar('File too large', { variant: 'error' });
        // toast({
        //   title: "File too large",
        //   description: `${file.name} is larger than 5MB`,
        //   variant: "destructive",
        // });
        return true;
      }

      if (!allowedFileTypes.includes(file.type)) {
        enqueueSnackbar(
          `${file.name} is not an accepted file type (TXT, MD, HTML, DOCX, PPTX, PDF)`,
          { variant: 'error' }
        );
        return true; // Indicate invalid file type
      }
      return false; // File is valid
    });

    const validFiles = files.filter((file) => !invalidFiles.includes(file));
    if (validFiles.length > 0) {
      onFileSelect(validFiles);
    }

    // Reset the input
    if (event.target) {
      event.target.value = '';
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        style={{ display: 'none' }}
        accept=".txt,.md,.html,.docx,.pptx,.pdf"
        multiple
      />
      {uploadType === 'Button' ? (
        <Button
          onClick={() => fileInputRef.current?.click()}
          startIcon={<Upload />}
          disabled={disabled}
          sx={{
            ml: 1,
            border: 1,
            minHeight: 80,
            maxWidth: 100,
            mb: 1,
            color: darkMode ? 'white' : theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.text.primary,
            },
          }}
        >
          {uploadLabel || 'Upload'}
        </Button>
      ) : (
        <IconButton
          onClick={() => fileInputRef.current?.click()}
          disabled={disabled}
          sx={{
            ml: 1,
            mb: 1,
            color: darkMode ? 'white' : theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.text.primary,
            },
          }}
        >
          <Upload />
        </IconButton>
      )}
    </>
  );
}
