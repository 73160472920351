import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'src/redux/store';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
  closestCorners,
} from '@dnd-kit/core';

import { arrayMove } from '@dnd-kit/sortable';
import {
  saveAiFlow,
  selectAiFlowItem,
  selectAiFlowItemBySequence,
  setNodeClickedSequenceInfo,
  setSidebarPopoverOpen,
  updateSelectedAiFlow,
} from 'src/redux/slices/aiflows';
import _ from 'lodash';
import { AiFlowBranch, AiFlowItem, AiFlowItemNode } from 'src/@types/aiflow';
import { ActiveTypes, ActiveTypesSequences } from 'src/common/constants/active-types.constants';
import { isValidItemToBePlaced } from 'src/utils/aiFlows-validator-utils';
import { AiFlowItemLogic, AiFlowLogic } from 'src/api';
import { useSnackbar } from 'src/components/snackbar';
import { orderItemsBySequence, orderItemsSequence } from 'src/utils/aiFlowsUtils';
import { useQuery } from 'src/utils/query';
import { useNavigate } from 'react-router';
import AiFlowSidebarDrawer from '../drawer/AiFlowSidebarDrawer';
import { AiFlowItemType } from '../enums/AiFlowItemType.enum';
import AiFlowAction from '../item-cards/AiFlowAction';

import { ReactFlow, Background, Edge, ProOptions, ReactFlowProvider } from '@xyflow/react';

import '@xyflow/react/dist/style.css';
import nodeTypes from './NodeTypes';
import edgesTypes from './EdgeTypes';
import AiFlowSidebarPopover from './popovers/AiFlowSidebarPopover';
import SkeletonWorkflowDesigner from 'src/components/skeleton/SkeletonWorkflowDesigner';

interface Props {
  sidebarHidden?: boolean;
  viewOnly?: boolean;
  height?: number;
}

export default function WorkflowDesigner({ sidebarHidden, height, viewOnly }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actions = useSelector((state) => state.aiFlows.actions);
  const fullscreen = useSelector((state) => state.aiFlows.fullscreen);
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const fetchingAiFlows = useSelector((state) => state.aiFlows.loading);
  const selectedAiFlowVersion = useSelector((state) => state.aiFlows.selectedAiFlowVersion);
  const selectedAiFlowRun = useSelector((state) => state.aiFlowsRuns.selectedAiFlowRun);
  const aiFlowRunOutputs = useSelector((state) => state.aiFlowsRuns.aiFlowRunsOutputs);
  const [initialLoad, setInitialLoad] = useState(false);
  const [aiFlowItemsSequences, setAiFlowItemsSequences] = useState<string[]>([]);
  const [draggedActiveId, setDraggedActiveId] = useState<number>(0);
  const [draggedAiFlowItem, setDraggedAiFlowItem] = useState<AiFlowItem | null>(null);
  const [draggedOverId, setDraggedOverId] = useState<number>(0);
  const [actionsSequences, setActionsSequences] = useState<string[]>([]);
  const [currentAiFlowVersion, setCurrentAiFlowVersion] = useState<number | null>();
  const [aiFlowItems, setAiFlowItems] = useState<AiFlowItem[]>([]);
  const [aiFlowItemsAdd, setAiFlowItemsAdd] = useState<AiFlowItem[]>([]);
  const runsMode = useSelector((state) => state.aiFlows.runsMode);
  const agentSetupMode = useSelector((state) => state.appStates.agentSetupMode);
  const editorFullScreenMode = useSelector((state) => state.editor.fullScreen);
  const savingAiFlow = useSelector((state) => state.aiFlows.savingAiFlow);
  const selectedAiFlowItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const lastSavedAiFlow = useSelector((state) => state.aiFlows.lastSavedSelectedAiFlow);
  const isAiFlowDirty = useSelector((state) => state.aiFlows.isDirty);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const loaded = useSelector((state) => state.aiFlows.loaded);
  const aiFlowRunItems = useSelector((state) => state.aiFlowsRuns.aiFlowRunItems);
  const [runsModeFlowLoaded, setRunsModeFlowLoaded] = useState(true);
  const query = useQuery();
  const itemFromQuery = query.get('item');
  // const onDragEnd = useCallback(async ({ destination, source, draggableId, type }: DropResult) => {
  //   try {
  //     if (!destination) {
  //       return;
  //     }

  //     if (destination.droppableId === source.droppableId && destination.index === source.index) {
  //       return;
  //     }
  //   } catch (err) {
  //   }
  // }, []);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor)
  );
  const moveElement = (arr: any[], fromIndex: number, toIndex: number) => {
    const item = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, item);
    return arr;
  };

  useEffect(() => {
    if (loaded && !initialLoad) {
      setInitialLoad(true);
    }
  }, [loaded, initialLoad]);

  const getTypeByActiveId = (activeId: number) => {
    if (activeId >= ActiveTypes.Action && activeId < ActiveTypes.Validator) {
      return AiFlowItemType.Action;
    }
    if (activeId >= ActiveTypes.Validator && activeId < ActiveTypes.Delay) {
      return AiFlowItemType.Validator;
    }
    if (activeId >= ActiveTypes.Delay) {
      return AiFlowItemType.Delay;
    }
    return AiFlowItemType.Action;
  };

  const shiftOneSequenceArrayItems = useCallback(
    (wfItems: AiFlowItem[], activeId: number, overId: number) => {
      moveElement(wfItems, activeId - 1, overId - 1);
      wfItems = wfItems.map((item, index) => {
        item.sequence = index + 1;
        return item;
      });
    },
    []
  );

  // const getInfoDataByItemType = (
  //   itemType: AiFlowItemType,
  //   eventCurrentData: any
  // ): InfoDataLogic => {
  //   if (itemType === AiFlowItemType.Action) {
  //     return { actionInfoData: { actionType: eventCurrentData.actionInfo?.header } };
  //   } else if (itemType === AiFlowItemType.Validator) {
  //     return {};
  //   } else if (itemType === AiFlowItemType.Delay) {
  //     return {};
  //   }
  //   return undefined as any;
  // };

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const activeId = event.active.id as number;

      if (activeId >= ActiveTypes.Action) {
        setAiFlowItems(aiFlowItemsAdd);
        const item: AiFlowItem = {
          actionInfo: event.active.data.current?.actionInfo,
          //   type: AiFlowItemType.Action,
        };
        setDraggedAiFlowItem(item as AiFlowItem);
        //  dispatch(addAiFlowItem({ aiFlowItem: item as AiFlowItem }));
        // const tmpAiFlowItems = _.cloneDeep(aiFlowItems);
        // tmpAiFlowItems.unshift(item as AiFlowItem);
        // setAiFlowItems(tmpAiFlowItems);
        //   moveElement(aiFlowItems, activeId - 1, overId - 1);
      }

      setDraggedActiveId(activeId);
      // const item = {
      //   data: JSON.stringify({ action: actions[0] }),
      //   actionInfo: actions[0],
      //   type: AiFlowItemType.Action,
      //   sequence: 1001,
      // };

      // dispatch(addAiFlowItem({ aiFlowItem: item as AiFlowItem }));
    },
    [aiFlowItemsAdd]
  );
  const handleDragOver = useCallback((event: DragOverEvent) => {
    const overId = parseInt(event.over ? event.over.id.toString() : '0', 10);
    const activeId = parseInt(event.active?.id.toString(), 10);
    setDraggedOverId(overId);

    // const overId = parseInt(event.over ? event.over.id.toString() : '0', 10);
    // const activeId = parseInt(event.active?.id.toString(), 10);
    // if (activeId >= 1000 && selectedAiFlow) {
    //
    //   const tmpAiFlow = _.cloneDeep(selectedAiFlow);
    //   let tmpAiFlowItems = tmpAiFlow.items as AiFlowItem[];
    //   tmpAiFlowItems = tmpAiFlowItems.filter((item) => !item.dragging);
    //   const item: AiFlowItem = {
    //     data: JSON.stringify({ action: actions[0] }),
    //     actionInfo: actions[6],
    //     type: AiFlowItemType.Action,
    //     aiFlowId: selectedAiFlow.id as number,
    //     sequence: overId + 1,
    //     dragging: true,
    //   };
    //   tmpAiFlowItems.splice(overId, 0, item);
    //   // setAiFlowItemsSequences((sequence) => {
    //   //   const oldIndex = sequence.indexOf(activeId.toString());
    //   //   const newIndex = sequence.indexOf(overId.toString());

    //   //   return arrayMove(aiFlowItemsSequences, oldIndex, newIndex);
    //   // });
    //   tmpAiFlow.items = tmpAiFlowItems;
    //   dispatch(updateSelectedAiFlow(tmpAiFlow));
    //   //  dispatch(addAiFlowItem({ aiFlowItem: item as AiFlowItem }));
    // }
  }, []);
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      let tmpAiFlowItems: AiFlowItem[] = [] as AiFlowItem[];
      let tmpAiFlow;
      if (selectedAiFlow) {
        tmpAiFlow = _.cloneDeep(selectedAiFlow);
        tmpAiFlowItems = tmpAiFlow.items ? (tmpAiFlow.items as AiFlowItem[]) : [];
      }
      let activeId = parseInt(active.id.toString(), 10);
      let overId = parseInt(over ? over.id.toString() : '0', 10);

      if (activeId >= ActiveTypes.Action && overId < ActiveTypes.Action) {
        setAiFlowItems(tmpAiFlowItems);
        return;
      }

      if (
        !isValidItemToBePlaced(
          activeId,
          tmpAiFlowItems.find((item) => item.sequence === overId - 1000),
          tmpAiFlowItems.find((item) => item.sequence === overId - 1000 + 1),
          event.active.data.current?.actionInfo?.header,
          overId - 1000 + 1
        )
      ) {
        enqueueSnackbar('Invalid action placement', { variant: 'error' });
        setAiFlowItems(tmpAiFlowItems);
        return;
      }

      if (overId >= ActiveTypes.Action && activeId >= ActiveTypes.Action) {
        const item: AiFlowItem = {
          actionInfo: event.active.data.current?.actionInfo,
          // type: getTypeByActiveId(activeId),
          sequence: overId - 1000 + 1,
          aiFlowVersionId: selectedAiFlowVersion?.id as number,
          aiFlowId: selectedAiFlow?.id as number,
          header: event.active.data.current?.actionInfo?.header,
        };
        aiFlowItemsSequences.push((overId - 1000 + 1).toString());
        tmpAiFlowItems.splice(overId - 1000, 0, item);
        tmpAiFlowItems = tmpAiFlowItems.map((wfItem, index) => {
          wfItem.sequence = index + 1;
          return wfItem;
        });
        // if (tmpAiFlowItems.length === 1) {
        //   tmpAiFlowItems.push({ type: AiFlowItemType.Finish, sequence: 2 });
        // }
        if (tmpAiFlow) {
          tmpAiFlow.items = tmpAiFlowItems;
        } else {
          tmpAiFlow = { items: tmpAiFlowItems };
        }
        dispatch(updateSelectedAiFlow(tmpAiFlow));
        setAiFlowItems(tmpAiFlowItems);
        setDraggedActiveId(0);
        const tmpSequences: string[] = [];
        for (let i = 0; i < tmpAiFlowItems.length; i++) {
          if (tmpAiFlowItems && tmpAiFlowItems[i]) {
            tmpSequences.push(tmpAiFlowItems[i].sequence!.toString());
          }
        }
        // setAiFlowItemsSequences((sequences) => {
        //   const oldIndex = sequences.indexOf(activeId.toString());
        //   const newIndex = sequences.indexOf(overId.toString());
        //
        //   return [...tmpSequences];
        // });
        return;
      }

      if (activeId >= ActiveTypesSequences.Validator && activeId < ActiveTypes.Action) {
        activeId %= 100;
      }
      if (overId >= ActiveTypesSequences.Validator && overId < ActiveTypes.Action) {
        overId %= 100;
      }
      const activeItem = tmpAiFlowItems.find((item) => item.sequence === activeId);
      activeItem!.sequence = overId;
      // moveElement(tmpAiFlowItems, activeId - 1, overId - 1);
      // tmpAiFlowItems = tmpAiFlowItems.map((item, index) => {
      //   item.sequence = index + 1;
      //   return item;
      // });

      shiftOneSequenceArrayItems(tmpAiFlowItems, activeId, overId);

      setAiFlowItems(tmpAiFlowItems);
      if (tmpAiFlow) {
        tmpAiFlow.items = tmpAiFlowItems;
        dispatch(updateSelectedAiFlow(tmpAiFlow));
      }
      dispatch(selectAiFlowItemBySequence(null));
      const tmpSequences: string[] = [];
      for (let i = 0; i < tmpAiFlowItems.length; i++) {
        if (tmpAiFlowItems && tmpAiFlowItems[i]) {
          tmpSequences.push(tmpAiFlowItems[i].sequence!.toString());
        }
      }
      setAiFlowItemsSequences((sequences) => {
        const oldIndex = sequences.indexOf(activeId.toString());
        const newIndex = sequences.indexOf(overId.toString());

        const movedArray = arrayMove(aiFlowItemsSequences, oldIndex, newIndex);
        return movedArray;
      });
    },
    [
      selectedAiFlow,
      aiFlowItemsSequences,
      enqueueSnackbar,
      shiftOneSequenceArrayItems,
      selectedAiFlowVersion,
      dispatch,
    ]
  );

  useEffect(() => {
    const tmpSequences: string[] = [];

    // if (actions) {
    //   for (const action of actions) {
    //     if (action) {
    //       tmpSequences.push((action && action.id ? action.id + 1000 : 0).toString());
    //     }
    //   }
    // }

    let wkItemsAdd: AiFlowItem[] = [];
    wkItemsAdd.push({ id: 1000, new: true });
    let items = [];
    if (runsMode) {
      if (selectedAiFlowRun && aiFlowRunItems) {
        items = aiFlowRunItems[selectedAiFlowRun!.id as number] ?? [];
        items = orderItemsBySequence(_.cloneDeep(items));
        if (!_.isEqual(aiFlowItems, items)) {
          setAiFlowItems(items);
        }
      }
    } else if (selectedAiFlow && selectedAiFlow.items) {
      if (draggedActiveId < ActiveTypes.Action) {
        items = selectedAiFlow.items;
        if (selectedAiFlowVersion?.id) {
          items = [
            ...selectedAiFlow.items.filter((item) =>
              item.aiFlowVersionId ? item.aiFlowVersionId === selectedAiFlowVersion?.id : true
            ),
          ];
        }

        items = orderItemsSequence(items);

        if (!_.isEqual(aiFlowItems, items) && !viewOnly) {
          setAiFlowItems(items);
          if (!selectedAiFlow?.id) {
            dispatch(
              saveAiFlow(
                {
                  name: selectedAiFlow?.name ?? 'untitled',
                  aiFlowType: selectedAiFlow?.aiFlowType,
                  draft: true,
                  items,
                },
                enqueueSnackbar,
                navigate,
                true
              )
            );
          }
        }
      }

      wkItemsAdd = wkItemsAdd.concat(
        aiFlowItems.reduce((accumulator: AiFlowItem[], currentValue) => {
          const newItem: AiFlowItem = { id: (currentValue.sequence as number) + 1000, new: true };
          if (Number.isNaN(newItem.id as number)) {
            return accumulator.concat([currentValue]);
          }
          // ^ This is just an example, replace it with actual construction logic of the new item
          return accumulator.concat([currentValue, newItem]);
        }, [])
      );

      for (let i = 0; i < selectedAiFlow.items?.length; i++) {
        if (selectedAiFlow.items && selectedAiFlow.items[i]) {
          tmpSequences.push((i + 1).toString());

          // else if (selectedAiFlow.items[i].type === AiFlowItemType.Validator) {
          //   tmpSequences.push(
          //     (
          //       ActiveTypesSequences.Validator + (selectedAiFlow.items[i].sequence as number)
          //     ).toString()
          //   );
          // } else if (selectedAiFlow.items[i].type === AiFlowItemType.Delay) {
          //   tmpSequences.push(
          //     (ActiveTypesSequences.Delay + (selectedAiFlow.items[i].sequence as number)).toString()
          //   );
          // }
        }
      }
      // foreach action in actions
      setAiFlowItemsSequences(tmpSequences);
    }
    setAiFlowItemsAdd(wkItemsAdd);
  }, [
    selectedAiFlow,
    aiFlowItems,
    dispatch,
    viewOnly,
    enqueueSnackbar,
    navigate,
    draggedActiveId,
    runsMode,
    aiFlowRunItems,
    selectedAiFlowRun,
    aiFlowRunOutputs,
    selectedAiFlowVersion,
  ]);

  // useEffect(() => {
  //   const tmpSequences: string[] = [];
  //   if (actions) {
  //     for (const action of actions) {
  //       if (action) {
  //         tmpSequences.push((action && action.id ? action.id + 1000 : 0).toString());
  //       }
  //     }
  //   }
  //   setActionsSequences(tmpSequences);
  // }, [actions]);
  const theme = useTheme();
  const matchesXXL = useMediaQuery(theme.breakpoints.up(2000));

  const itemsPerRow = () => {
    if (sidebarHidden && !fullscreen) {
      return 'repeat(2, 1fr)';
    }
    if (matchesXXL) {
      return 'repeat(5, 1fr)';
    }
    return fullscreen ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)';
  };

  // const defaultNodes: Node[] = [
  //   {
  //     id: '1',
  //     data: { label: '🌮 Taco' },
  //     position: { x: 0, y: 0 },
  //     type: 'workflow',
  //   },
  //   {
  //     id: '2',
  //     data: { label: '+' },
  //     position: { x: 0, y: 150 },
  //     type: 'placeholder',
  //   },
  // ];

  const [fitViewOptions, setFitViewOptions] = useState<any>({
    padding: 3,
  });

  const [nodes, setNodes] = useState<AiFlowItemNode[]>([]);
  // initial setup: connect the workflow node to the placeholder node with a placeholder edge
  const [edges, setEdges] = useState<Edge[]>([
    {
      id: '1=>2',
      source: '1',
      target: '2',
      type: 'placeholder',
    },
  ]);

  // Parsing Function
  const parseBranchesWithParents = useCallback((items: AiFlowItem[]): AiFlowBranch[] => {
    if (!items || items.length === 0) {
      return [];
    }

    const branchMap: { [key: number]: AiFlowBranch } = {};

    // Initialize branchMap
    items.forEach((item) => {
      branchMap[item.sequence as number] = {
        parentItem: item,
        children: [],
      };
    });

    const roots: AiFlowBranch[] = [];

    // Establish parent-child relationships
    items.forEach((item) => {
      if (item.parentSequence !== undefined && item.parentSequence !== null) {
        const parentBranch = branchMap[item.parentSequence];
        if (parentBranch) {
          parentBranch.children.push(branchMap[item.sequence as number]);
        } else {
          console.warn(
            `Parent sequence ${item.parentSequence} for item ${item.sequence} not found. Treating as root.`
          );
          roots.push(branchMap[item.sequence as number]);
        }
      } else {
        roots.push(branchMap[item.sequence as number]);
      }
    });

    return roots;
  }, []);

  const getCalculatedNodesEnhanced = useCallback(
    (
      items: AiFlowItem[],
      level: number = 0,
      parentX: number = 0,
      parentY: number = 0
    ): AiFlowItemNode[] => {
      const tmpNodes: AiFlowItemNode[] = [];
      const yIncrement = 220; // Vertical spacing between levels

      if (!items || items.length === 0) {
        if (runsMode || agentSetupMode) {
          return [];
        }
        tmpNodes.push({
          id: 'placeholder-0',
          data: { label: '+' },
          position: { x: parentX, y: parentY },
          type: 'placeholder',
        });
        return tmpNodes;
      }

      const parsedBranches = parseBranchesWithParents(items);

      // Function to calculate positions recursively
      const calculatePositions = (
        branch: AiFlowBranch,
        depth: number = 0
      ): { width: number; positions: { [sequence: number]: { x: number; y: number } } } => {
        const nodeWidth = 300; // Minimum width allocated for each node
        const nodeSpacing = 80; // Minimum horizontal spacing between nodes
        const positions: { [sequence: number]: { x: number; y: number } } = {};
        let width = 0;

        if (branch.children.length === 0) {
          // Leaf node
          width = nodeWidth;
          positions[branch.parentItem.sequence as number] = { x: 0, y: depth * yIncrement };
        } else {
          // Non-leaf node
          const childResults = branch.children.map((child) => calculatePositions(child, depth + 1));
          const childWidths = childResults.map((result) => result.width);
          const totalChildrenWidth =
            childWidths.reduce((sum, w) => sum + w, 0) + nodeSpacing * (childWidths.length - 1);

          width = Math.max(nodeWidth, totalChildrenWidth);

          let xOffset = -(totalChildrenWidth / 2);
          branch.children.forEach((childBranch, index) => {
            const childWidth = childWidths[index];
            const childResult = childResults[index];

            xOffset += childWidth / 2;

            // Adjust child positions
            Object.entries(childResult.positions).forEach(([seq, pos]) => {
              positions[seq as any] = {
                x: pos.x + xOffset,
                y: pos.y,
              };
            });

            xOffset += childWidth / 2 + nodeSpacing;
          });

          // Position current node at the center of its children
          const firstChildX = positions[branch.children[0].parentItem.sequence as number].x;
          const lastChildX =
            positions[branch.children[branch.children.length - 1].parentItem.sequence as number].x;
          const parentXPos = (firstChildX + lastChildX) / 2;

          positions[branch.parentItem.sequence as number] = {
            x: parentXPos,
            y: depth * yIncrement,
          };
        }

        return { width, positions };
      };

      // Collect all positions
      const allPositions: { [sequence: number]: { x: number; y: number } } = {};

      parsedBranches.forEach((branch) => {
        const { positions } = calculatePositions(branch);
        Object.assign(allPositions, positions);
      });

      // Create nodes based on calculated positions
      items.forEach((item) => {
        const position = allPositions[item.sequence as number];
        if (position) {
          tmpNodes.push({
            id: `${item.sequence}`,
            data: { label: item.header, item },
            position: { x: position.x + parentX, y: position.y + parentY },
            type: 'workflow',
          });
        }
      });

      // Add placeholders where necessary
      items.forEach((item) => {
        const position = allPositions[item.sequence as number];
        const hasChildren = items.some((child) => child.parentSequence === item.sequence);
        if (position && !hasChildren && !runsMode && !agentSetupMode) {
          tmpNodes.push({
            id: `placeholder-${item.sequence}`,
            data: { label: '+', parentItem: item },
            position: { x: position.x + parentX, y: position.y + yIncrement + parentY },
            type: 'placeholder',
          });
        }
      });

      return tmpNodes;
    },
    [parseBranchesWithParents, runsMode, agentSetupMode]
  );

  // Edge Generation Function
  const getCalculatedEdgesEnhanced = useCallback(
    (items: AiFlowItem[]): Edge[] => {
      const tmpEdges: Edge[] = [];

      if (!items || items.length === 0) {
        return tmpEdges;
      }

      const parsedBranches = parseBranchesWithParents(items);

      // Create a set to track added edges to prevent duplicates
      const addedEdges = new Set<string>();

      // Helper function to traverse branches and create edges
      const traverseBranches = (branch: AiFlowBranch) => {
        const currentId = `${branch.parentItem.sequence}`;

        // if no children, connect to placeholder
        if (branch.children.length === 0) {
          if (runsMode || agentSetupMode) {
            return;
          }
          tmpEdges.push({
            id: `e${currentId}->placeholder-${branch.parentItem.sequence}`,
            source: currentId,
            target: `placeholder-${branch.parentItem.sequence}`,
            type: 'placeholder',
          });
        }

        branch.children.forEach((childBranch) => {
          const childId = `${childBranch.parentItem.sequence}`;
          const edgeId = `e${currentId}->${childId}`;
          if (!addedEdges.has(edgeId)) {
            tmpEdges.push({
              id: edgeId,
              source: currentId,
              target: childId,
              type: 'workflow',
            });
            addedEdges.add(edgeId);
          }

          if (childBranch.children.length > 0) {
            traverseBranches(childBranch);
          } else {
            if (runsMode || agentSetupMode) {
              return;
            }
            // Connect to placeholder if no further children
            const placeholderId = `placeholder-${childBranch.parentItem.sequence}`;
            const placeholderEdgeId = `e${childId}->placeholder-${childBranch.parentItem.sequence}`;
            if (!addedEdges.has(placeholderEdgeId)) {
              tmpEdges.push({
                id: placeholderEdgeId,
                source: childId,
                target: placeholderId,
                type: 'placeholder',
                style: { stroke: '#999', strokeDasharray: '5,5' }, // Dashed line for placeholders
              });
              addedEdges.add(placeholderEdgeId);
            }
          }
        });
      };

      // Traverse all root branches
      parsedBranches.forEach((branch) => {
        traverseBranches(branch);
      });

      return tmpEdges;
    },
    [parseBranchesWithParents, runsMode, agentSetupMode]
  );

  const getPaddingsByNodeCount = (nodeCount: number) => {
    if (nodeCount < 1) {
      return 3;
    }
    if (nodeCount < 3) {
      return 1;
    }
    if (nodeCount < 4) {
      return 0.3;
    }
    return 0.1;
  };
  const reactFlowInstance = useRef<any>(null); // Ref to store ReactFlow instance
  const [isReactFlowReady, setIsReactFlowReady] = useState(false);
  useEffect(() => {
    // Ensure that reactFlowInstance.current is set and nodes are available
    if (isReactFlowReady && nodes.length > 0) {
      const containerWidth = containerRef.current?.clientWidth || 0;

      const zoomLevel = 0.6; // Adjust zoom level as needed
      const x = containerWidth / 2 - 110;
      const y = 20;

      reactFlowInstance.current.setViewport({ x, y, zoom: zoomLevel });
      setIsReactFlowReady(false);
    }
  }, [isReactFlowReady, nodes]);

  useEffect(() => {
    // map aiflow items to nodes
    if (selectedAiFlowItem) {
      return;
    }
    let items = selectedAiFlow?.items;
    if (selectedAiFlowVersion?.id) {
      items = [
        ...(selectedAiFlow?.items?.filter((item) =>
          item.aiFlowVersionId ? item.aiFlowVersionId === selectedAiFlowVersion?.id : true
        ) ?? []),
      ];
    }
    if (runsMode) {
      items = aiFlowRunItems[selectedAiFlowRun?.id as number];
      if (items && items.length > 0) {
        items = orderItemsBySequence(_.cloneDeep(items));
      }
    }

    const tmpNodes = getCalculatedNodesEnhanced(items as AiFlowItem[]) ?? [];
    const tmpEdges = getCalculatedEdgesEnhanced(items as AiFlowItem[]) ?? [];
    if (!runsMode) {
      // tmpNodes.push({
      //   id: (tmpNodes.length + 1).toString(),
      //   data: { label: '+' },
      //   position: { x: 0, y: 220 * (tmpNodes.length + 1) },
      //   type: 'placeholder',
      // });
    }

    if (!_.isEqual(tmpNodes, nodes)) {
      setNodes(tmpNodes);
    }

    if (!_.isEqual(tmpEdges, edges)) {
      setEdges(tmpEdges);
      // Calculate the center position for the first few nodes
      // Calculate the center position with a weighted X center
      const initialNodes = tmpNodes.slice(0, 5); // Adjust as needed
      // const allNodesCenterX = tmpNodes.reduce((sum, node) => sum + node.position.x, 0) / tmpNodes.length;
      // const initialCenterX = initialNodes.reduce((sum, node) => sum + node.position.x, 0) / initialNodes.length;
      // const centerY = initialNodes.reduce((sum, node) => sum + node.position.y, 0) / initialNodes.length;

      // Use a weighted average to center more towards the start but adjust for full width
      //  const weightedCenterX = (initialCenterX + allNodesCenterX) / 2;

      // Set fit view options to focus and center on the first part of the flow
      // setFitViewOptions({
      //  // padding: getPaddingsByNodeCount(tmpEdges.length), // Adjust padding to control zoom level
      //  // padding: -0.2,
      //  padding: 0.2,
      //   nodes:  tmpNodes,
      //   minZoom: 0.5,    // Set minimum zoom level to prevent zooming out too much
      //   maxZoom: 1.5,    // Optional: set maximum zoom level
      // });

      // const initialNodesBounds = getRectOfNodes(initialNodes);
      // const centerX = initialNodesBounds.x + initialNodesBounds.width / 2;
      // const centerY = initialNodesBounds.y + initialNodesBounds.height / 2;

      // const containerWidth = containerRef.current?.clientWidth || window.innerWidth;
      // const containerHeight = containerRef.current?.clientHeight || window.innerHeight;

      // const zoomLevel = 0.5; // Adjust zoom level as needed
      // const x = -centerX * zoomLevel + containerWidth / 2;
      // const y = -centerY * zoomLevel + containerHeight / 2;
      //   if (reactFlowInstance.current){
      //     console.log('aaaddaaaa', { x, y, zoom: zoomLevel });
      //     reactFlowInstance.current.setViewport({ x, y, zoom: zoomLevel });

      //   }

      //  if (runsMode) {
      if (runsMode || !initialLoad || agentSetupMode) {
        setRunsModeFlowLoaded(false);
        setTimeout(() => {
          if (runsMode) {
            // setFitViewOptions({
            //   padding: 0.1,
            // });
          }
          setRunsModeFlowLoaded(true);
        }, 5);
      }

      //   }
    }
  }, [
    selectedAiFlow,
    reactFlowInstance,
    getCalculatedNodesEnhanced,
    getCalculatedEdgesEnhanced,
    nodes,
    agentSetupMode,
    initialLoad,
    edges,
    selectedAiFlowItem,
    runsMode,
    aiFlowRunItems,
    selectedAiFlowRun,
    selectedAiFlowVersion,
  ]);

  useEffect(() => {
    if (selectedAiFlowVersion && currentAiFlowVersion !== selectedAiFlowVersion.id) {
      setCurrentAiFlowVersion(selectedAiFlowVersion.id);
      setRunsModeFlowLoaded(false);
      setTimeout(() => {
        if (runsMode) {
          // setFitViewOptions({
          //   padding: 0.1,
          // });
        }
        setRunsModeFlowLoaded(true);
      }, 5);
    }
  }, [selectedAiFlowVersion, currentAiFlowVersion, runsMode]);

  // const getCalculatedNodes = (items: any[]) => {
  //   const tmpNodes: AiFlowItemNode[] = [];
  //   let y = 0;
  //   const yIncrement = 220; // Vertical spacing between nodes
  //   const xOffset = 400; // Horizontal spacing between branches

  //   // If items are empty, add a placeholder node
  //   if (!items || items.length === 0 && !runsMode) {
  //     tmpNodes.push({
  //       id: '1',
  //       data: { label: '+' },
  //       position: { x: 0, y },
  //       type: 'placeholder',
  //     });
  //     return tmpNodes;
  //   }

  //   let i = 0;
  //   while (i < items.length) {
  //     const item = items[i];

  //     if (item.header === 'Branches') {
  //       // Place the 'Branch' node
  //       tmpNodes.push({
  //         id: item.sequence.toString(),
  //         data: { label: item.header, item },
  //         item,
  //         position: { x: 0, y },
  //         type: 'workflow',
  //       });

  //       // Collect branches
  //       const branches = [];
  //       let j = i + 1;
  //       while (j < items.length && items[j].header === 'Branch') {
  //         // For each 'BranchConditions', collect its items
  //         const branchItems = [];
  //         const branchRootItem = items[j];
  //         j++; // Move to the next item

  //         while (
  //           j < items.length &&
  //           items[j].header !== 'Branch' &&
  //           items[j].header !== 'Branches'
  //         ) {
  //           branchItems.push(items[j]);
  //           j++;
  //         }

  //         branches.push({
  //           branchRootItem,
  //           branchItems,
  //         });

  //         // Do not increment j here, as it has been incremented inside
  //       }

  //       const numBranches = branches.length;
  //       const startX = -((numBranches - 1) / 2) * xOffset; // Center branches horizontally

  //       for (let k = 0; k < numBranches; k++) {
  //         const branchX = startX + k * xOffset;
  //         let branchY = y + yIncrement;

  //         const { branchRootItem, branchItems } = branches[k];
  //         console.log('branchRootItem', branchRootItem);
  //         // Place 'BranchConditions' node
  //         tmpNodes.push({
  //           id: `${branchRootItem.sequence}`,
  //           data: { label: branchRootItem.header, item: branchRootItem },
  //           item: branchRootItem,
  //           position: { x: branchX, y: branchY },
  //           type: 'workflow',
  //         });

  //         // Place nodes in the branch
  //         for (let m = 0; m < branchItems.length; m++) {
  //           branchY += yIncrement;
  //           const branchItem = branchItems[m];
  //           tmpNodes.push({
  //             id: `${branchItem.sequence}`,
  //             data: { label: branchItem.header, item: branchItem },
  //             item: branchItem,
  //             position: { x: branchX, y: branchY },
  //             type: 'workflow',
  //           });
  //         }
  //         const lastBranchItemSequence =
  //           branchItems[branchItems.length - 1]?.sequence ?? branchRootItem?.sequence;
  //         console.log('lastBranchItemSequence', lastBranchItemSequence);
  //         // Add placeholder at the end of the branch
  //         branchY += yIncrement;
  //         if (!runsMode) {
  //         tmpNodes.push({
  //           id: `placeholder-b-${i}-${k}-${lastBranchItemSequence}`,
  //           data: { label: '+' },
  //           position: { x: branchX, y: branchY },
  //           type: 'placeholder',
  //         });
  //       }
  //       }

  //       // Adjust y to move past the branches
  //       y += yIncrement * (2 + Math.max(...branches.map((b) => b.branchItems.length)));

  //       i = j; // Move to the next item after branches
  //     } else {
  //       // Normal node
  //       tmpNodes.push({
  //         id: item.sequence.toString(),
  //         data: { label: item.header, item },
  //         item,
  //         position: { x: 0, y },
  //         type: 'workflow',
  //       });

  //       // Add placeholder if it's the last node
  //       if (i === items.length - 1 && !runsMode) {
  //         const placeholderId = `placeholder-${i}`;
  //         tmpNodes.push({
  //           id: placeholderId,
  //           data: { label: '+' },
  //           position: { x: 0, y: y + yIncrement },
  //           type: 'placeholder',
  //         });
  //       }

  //       y += yIncrement;
  //       i++;
  //     }
  //   }
  //   console.log('tmpNodes', tmpNodes);
  //   return tmpNodes;
  // };

  // const getCalculatedEdges = (items: any[]) => {
  //   const tmpEdges: Edge[] = [];
  //   let i = 0;

  //   if (!items || items.length === 0) {
  //     // return placeholder
  //     tmpEdges.push({
  //       id: '1=>placeholder',
  //       source: '1',
  //       target: 'placeholder',
  //       type: 'placeholder',
  //     });
  //     return tmpEdges;
  //   }

  //   while (items && items.length && i < items.length) {
  //     const item = items[i];

  //     if (item.header === 'Branches') {
  //       const branchNodeId = item.sequence;
  //       const branchI = i; // Capture the current value of 'i'
  //       let j = i + 1;
  //       const branches = [];

  //       while (j < items.length && items[j].header === 'Branch') {
  //         const branchConditionIndex = j;
  //         const branchConditionItem = items[j];
  //         j++; // Move to the next item

  //         const branchItems = [];
  //         while (
  //           j < items.length &&
  //           items[j].header !== 'Branch' &&
  //           items[j].header !== 'Branches'
  //         ) {
  //           branchItems.push({ item: items[j], index: j });
  //           j++;
  //         }

  //         branches.push({
  //           branchConditionIndex,
  //           branchConditionItem,
  //           branchItems,
  //         });
  //       }

  //       // Replace 'forEach' with a 'for' loop to avoid closure issues
  //       for (let branchIdx = 0; branchIdx < branches.length; branchIdx++) {
  //         const branch = branches[branchIdx];
  //         const branchConditionId = `${branch.branchConditionItem.sequence}`;

  //         // Edge from 'Branch' node to 'BranchConditions' node
  //         tmpEdges.push({
  //           id: `${branchNodeId}->${branchConditionId}`,
  //           source: `${branchNodeId}`,
  //           target: branchConditionId,
  //           type: 'workflow',
  //         });

  //         let previousNodeId = branchConditionId;

  //         // Replace 'forEach' with a 'for' loop
  //         for (let idx = 0; idx < branch.branchItems.length; idx++) {
  //           const branchItemInfo = branch.branchItems[idx];
  //           const nodeId = `${branchItemInfo.item.sequence}`;

  //           // Edge from previous node to current node
  //           tmpEdges.push({
  //             id: `${previousNodeId}->${nodeId}`,
  //             source: `${previousNodeId}`,
  //             target: nodeId,
  //             type: 'workflow',
  //           });

  //           previousNodeId = nodeId;
  //         }

  //         const lastBranchItemSequence =
  //           branch.branchItems[branch.branchItems.length - 1]?.item?.sequence ??
  //           branch?.branchConditionItem?.sequence;
  //         console.log('lastBranchItemSequence', lastBranchItemSequence);
  //         // Edge to placeholder
  //         const placeholderId = `placeholder-b-${branchI}-${branchIdx}-${lastBranchItemSequence}`;
  //         tmpEdges.push({
  //           id: `${previousNodeId}->${placeholderId}`,
  //           source: previousNodeId,
  //           target: placeholderId,
  //           type: 'placeholder',
  //         });
  //       }

  //       // Move to the next item after processing branches
  //       i = j;
  //     } else {
  //       // For normal items, connect to the next item or a placeholder if it's the last node
  //       const currentNodeId = `${items[i].sequence}`;
  //       console.log('currentNodeId', currentNodeId);
  //       if (i + 1 < items.length) {
  //         tmpEdges.push({
  //           id: `${currentNodeId}->${Number(currentNodeId) + 1}`,
  //           source: currentNodeId,
  //           target: (Number(currentNodeId) + 1).toString(),
  //           type: 'workflow',
  //         });
  //       } else {
  //         // Edge to a placeholder node
  //         const placeholderId = `placeholder-${i}`;
  //         tmpEdges.push({
  //           id: `${currentNodeId}->${placeholderId}`,
  //           source: currentNodeId,
  //           target: placeholderId,
  //           type: 'placeholder',
  //         });
  //       }
  //       i++;
  //     }
  //   }
  //   console.log('tmpEdges', tmpEdges);
  //   return tmpEdges;
  // };

  const handleDragCancel = useCallback(() => {
    const originalAiFlowItems = selectedAiFlow?.items;
    setAiFlowItems(originalAiFlowItems as AiFlowItem[]);
  }, [selectedAiFlow]);

  useEffect(() => {
    if (
      selectedAiFlow?.id &&
      !savingAiFlow &&
      loaded &&
      !selectedAiFlowItem &&
      !runsMode &&
      !agentSetupMode &&
      isAiFlowDirty &&
      !_.isEqual(selectedAiFlow, lastSavedAiFlow)
    ) {
      dispatch(saveAiFlow(selectedAiFlow as AiFlowLogic, enqueueSnackbar));
    }
  }, [
    selectedAiFlow,
    selectedAiFlowItem,
    dispatch,
    enqueueSnackbar,
    savingAiFlow,
    agentSetupMode,
    runsMode,
    loaded,
    isAiFlowDirty,
    lastSavedAiFlow,
  ]);

  // Initialize the placeholder node with a fixed ID
  const placeholderNode = {
    id: 'placeholder',
    data: { label: '+' },
    position: { x: 0, y: 0 },
    type: 'placeholder',
  };
  const [nextNodeId, setNextNodeId] = useState(1);

  const dashboardWidgetsData = useSelector((state) => state.dashboard.dashboardWidgetsData);
  const proOptions: ProOptions = { account: 'paid-pro', hideAttribution: true };
  useEffect(() => {
    if (itemFromQuery && runsMode && dashboardWidgetsData?.recentOutputs) {
      const recentOutput = dashboardWidgetsData?.recentOutputs.find(
        (ro) => ro.item?.id === Number(itemFromQuery)
      );
      if (recentOutput) dispatch(selectAiFlowItem(recentOutput.item as AiFlowItemLogic));
    }
  }, [itemFromQuery, runsMode, dispatch, dashboardWidgetsData?.recentOutputs]);

  // const ReactFlowWrapper = () => {
  //   // useLayout();
  //   return (

  //   );
  // };
  const [transform, setTransform] = useState({ x: 0, y: 0, zoom: 1 });
  const containerRef = useRef<any>(null);

  // Custom scroll handler for panning instead of zooming
  const onWheelScroll = useCallback((event: any) => {
    event.preventDefault();

    if (event.deltaY !== 0) {
      // Adjust vertical scroll speed by multiplying deltaY with a factor
      setTransform((prev) => ({
        ...prev,
        y: prev.y - event.deltaY * 2, // Multiply deltaY for speed control
      }));
      if (containerRef.current) {
        // Here we let the container scroll natively
        containerRef.current.scrollTop += event.deltaY * 1.5; // Control the speed of the scroll
      }
    }
  }, []);

  // useEffect(() => {
  //   if (reactFlowInstance.current) {
  //     // This will fit all nodes into view and center them
  //     reactFlowInstance.current.fitView({ padding: 0.2 });
  //   }
  // }, [runsModeFlowLoaded]); // Run when the flow is loaded

  return (
    <>
      <Stack direction="row" spacing={3} sx={{ position: 'relative' }}>
        <>
          <DndContext
            sensors={sensors}
            autoScroll={{ layoutShiftCompensation: false }}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            {/* {!sidebarHidden && <AiFlowSideBar />} */}
            {!initialLoad && <SkeletonWorkflowDesigner />}
            <ReactFlowProvider>
              {runsModeFlowLoaded && initialLoad && (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: height ?? (runsMode ? '720px' : '640px'),
                      overflow: 'auto', // Scrollbars when the content overflows
                      border: '1px solid #ccc', // Optional for debugging
                      position: 'relative',
                    }}
                    ref={containerRef} // Reference to the scrollable div
                    onWheel={onWheelScroll} // Custom scroll/zoom handler
                  >
                    <ReactFlow
                      edges={edges}
                      nodes={nodes}
                      onNodeClick={(event, node) => {
                        if (viewOnly) {
                          return;
                        }
                        // Node click handler
                        if (node.type === 'placeholder') {
                          dispatch(
                            setNodeClickedSequenceInfo({
                              addBeforeSequence: Number(node.id.split('-')[1]) + 1,
                              addToParentSequence: (node?.data as any).parentItem?.sequence
                                ? (node.data as any).parentItem.sequence
                                : null,
                            })
                          );
                          dispatch(
                            setSidebarPopoverOpen({
                              element: event.currentTarget as HTMLElement,
                              position: 'left',
                            })
                          );
                        }
                      }}
                      onEdgeClick={(event, edge) => {
                        // Edge click handler
                        dispatch(
                          setNodeClickedSequenceInfo({
                            addBeforeSequence: Number(edge.target),
                            addToParentSequence: Number(edge.source),
                          })
                        );
                        dispatch(
                          setSidebarPopoverOpen({
                            element: event.currentTarget as HTMLElement,
                            position: 'left',
                          })
                        );
                      }}
                      // onNodesChange={(changedNodes) => {
                      //   const nodeCount = nodes.length;

                      //   // Calculate zoom level dynamically
                      //   // You can adjust the formula to suit your needs
                      //   const minZoom = 0.5;
                      //   const maxZoom = 1.5;
                      //   const zoomLevel = Math.max(minZoom, maxZoom - nodeCount * 0.1);

                      //   // Get the bounding box of all nodes to center the view
                      //   //  const nodesBounds = reactFlowInstance.current.getNodesBounds();

                      //   // Get the bounds of all nodes
                      //   const nodesBounds = reactFlowInstance.current.getNodesBounds();

                      //   // Calculate the center position
                      //   const viewportWidth = containerRef.current?.clientWidth || window.innerWidth;
                      //   const viewportHeight = containerRef.current?.clientHeight || window.innerHeight;
                      //   //   const x = viewportWidth / 2 - (nodesBounds.width / 2 + nodesBounds.x);
                      //   //    const y = viewportHeight / 2 - (nodesBounds.height / 2 + nodesBounds.y);

                      //   // Get the container dimensions
                      //   const containerWidth = containerRef.current?.clientWidth || window.innerWidth;
                      //   const containerHeight = containerRef.current?.clientHeight || window.innerHeight;

                      //   // Calculate the x position to center the nodes horizontally in the container
                      //   const x =
                      //     containerWidth / 2 - (nodesBounds.width * zoomLevel) / 2 - nodesBounds.x * zoomLevel;
                      //   console.log('x:', x);

                      //   // Apply the viewport transformation
                      //   reactFlowInstance.current.setViewport({ x, y: 100, zoom: zoomLevel });
                      //   //    console.log('nodesBounds', nodesBounds);
                      //   //      reactFlowInstance.current.fitView();
                      // }}
                      proOptions={proOptions}
                      nodeTypes={nodeTypes}
                      edgeTypes={edgesTypes}
                      minZoom={0.1}
                      maxZoom={2} // Limit the zoom levels if needed
                      nodesDraggable={false}
                      panOnScroll
                      fitView
                      fitViewOptions={fitViewOptions}
                      nodesConnectable={false}
                      zoomOnDoubleClick={false}
                      zoomOnScroll={false} // Disable zoom on scroll to handle zoom manually
                      translateExtent={[
                        [-Infinity, -Infinity],
                        [Infinity, Infinity],
                      ]}
                      onInit={(instance) => {
                        reactFlowInstance.current = instance; // Store ReactFlow instance for zoom control
                        setIsReactFlowReady(true); // Update state when instance is ready
                      }}
                    >
                      <Background />
                    </ReactFlow>
                  </div>
                </>
              )}
            </ReactFlowProvider>

            {draggedAiFlowItem && draggedActiveId > 0 && (
              <DragOverlay>
                <AiFlowAction
                  key={draggedActiveId}
                  index={draggedActiveId}
                  item={draggedAiFlowItem as AiFlowItem}
                />
              </DragOverlay>
            )}
          </DndContext>
        </>
        <AiFlowSidebarDrawer />
        <AiFlowSidebarPopover />
      </Stack>
    </>
  );
}
