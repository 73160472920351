import {
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Typography,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from '@mui/material';
import { X, Circle, Square, Package, Diamond, Check } from 'lucide-react';
import { SUBSCRIPTION_ICONS } from 'src/common/constants/icons.constants';
import Scrollbar from '../scrollbar';
import { useDispatch, useSelector } from 'src/redux/store';
import { StripeApi } from 'src/api';
import { updateSubscriptionPlan } from 'src/redux/slices/subscription';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../snackbar';

// Helper component for feature availability indicators
const FeatureIndicator = ({ available }: { available: boolean }) => {
  if (available) {
    return <Check color="green" size={20} />;
  }
  return <X color="#ea384c" size={20} />;
};

interface PricingDialogProps {
  open: boolean;
  onClose: () => void;
}

interface PricingPlan {
  name: string;
  price: string;
  aiAgents: string | number;
  deployments: string | number;
  formInterface: boolean;
  chatInterface: boolean;
  embedWebsites: boolean;
  removeBranding: boolean;
  customDomain: boolean;
  aiFlows: string | number;
  aiFlowRuns: string | number;
  promptTemplates: string | number;
  customActions: string | number;
  versionControl: boolean;
  userCount: string | number;
  roleBasedAccess: boolean;
  multiFactor: boolean;
  buttonText: string;
  icon: React.ReactNode;
}

const pricingPlans: PricingPlan[] = [
  {
    name: 'Free',
    price: '0$',
    aiAgents: 3,
    deployments: 10,
    formInterface: true,
    chatInterface: true,
    embedWebsites: true,
    removeBranding: false,
    customDomain: false,
    aiFlows: 20,
    aiFlowRuns: 20,
    promptTemplates: 20,
    customActions: 20,
    versionControl: true,
    userCount: 1,
    roleBasedAccess: false,
    multiFactor: false,
    buttonText: 'Get Started',
    icon: <Box sx={{ width: 40, height: 40, color: '#45CE4E' }}>{SUBSCRIPTION_ICONS.free}</Box>,
  },
  {
    name: 'Starter',
    price: '$29/Month',
    aiAgents: 20,
    deployments: '100/Month',
    formInterface: true,
    chatInterface: true,
    embedWebsites: true,
    removeBranding: true,
    customDomain: false,
    aiFlows: 'Unlimited',
    aiFlowRuns: 500,
    promptTemplates: 'Unlimited',
    customActions: 'Unlimited',
    versionControl: true,
    userCount: 1,
    roleBasedAccess: false,
    multiFactor: false,
    buttonText: 'Get Started',
    icon: <Box sx={{ width: 40, height: 40, color: '#45CE4E' }}>{SUBSCRIPTION_ICONS.starter}</Box>,
  },
  {
    name: 'Professional',
    price: '$149/Month',
    aiAgents: 100,
    deployments: 'Unlimited',
    formInterface: true,
    chatInterface: true,
    embedWebsites: true,
    removeBranding: true,
    customDomain: false,
    aiFlows: 'Unlimited',
    aiFlowRuns: 3000,
    promptTemplates: 'Unlimited',
    customActions: 'Unlimited',
    versionControl: true,
    userCount: 3,
    roleBasedAccess: true,
    multiFactor: true,
    buttonText: 'Get Started',
    icon: (
      <Box sx={{ width: 40, height: 40, color: '#45CE4E' }}>{SUBSCRIPTION_ICONS.professional}</Box>
    ),
  },
  {
    name: 'Company',
    price: 'Contact Us',
    aiAgents: 'Unlimited',
    deployments: 'Unlimited',
    formInterface: true,
    chatInterface: true,
    embedWebsites: true,
    removeBranding: true,
    customDomain: true,
    aiFlows: 'Unlimited',
    aiFlowRuns: 'Unlimited',
    promptTemplates: 'Unlimited',
    customActions: 'Unlimited',
    versionControl: true,
    userCount: 'Unlimited',
    roleBasedAccess: true,
    multiFactor: true,
    buttonText: 'Contact Us',
    icon: <Box sx={{ width: 40, height: 40, color: '#45CE4E' }}>{SUBSCRIPTION_ICONS.company}</Box>,
  },
];

export const NewPricingDialog = ({ open, onClose }: PricingDialogProps) => {
  const loading = useSelector((state) => state.organization.loading);
    const { enqueueSnackbar } = useSnackbar();
  const subscriptionLoading = useSelector((state) => state.subscription.loading);
  const basicBillingInfo = useSelector((state) => state.subscription.basicBillingInfo);
  const dispatch = useDispatch();
    const [loadingGenerateLink, setLoadingGenerateLink] = useState(false);
  const getPlanStatus = (subscriptionTier: string, index: number) => {
    const currentProductName = basicBillingInfo?.productName?.toLocaleLowerCase();
    const subscriptionName = subscriptionTier.toLocaleLowerCase();

    if (currentProductName === subscriptionName) {
      return 'Current Plan';
    }

    const currentPlanIndex = pricingPlans?.findIndex(
      (plan) => plan?.name?.toLocaleLowerCase() === currentProductName
    );

    return (currentPlanIndex ?? 1) < index ? 'Upgrade' : 'Downgrade';
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxWidth: '1200px',
          },
        }}
      >
        <DialogContent sx={{ p: 4 }}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'text.secondary',
            }}
          >
            <X size={24} />
          </IconButton>
          <Scrollbar>
            <Box sx={{ mb: 4 }}>
              {/* Header */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 4,
                }}
              >
                <Typography variant="h5" component="h2">
                  Compare all our plans
                </Typography>
                <Paper
                  elevation={0}
                  sx={{
                    bgcolor: 'grey.100',
                    borderRadius: '24px',
                    p: 0.5,
                  }}
                >
                  <ToggleButtonGroup
                    exclusive
                    value="monthly"
                    sx={{
                      '& .MuiToggleButton-root': {
                        border: 'none',
                        borderRadius: '20px',
                        px: 2,
                        py: 0.5,
                        '&.Mui-selected': {
                          bgcolor: 'success.main',
                          color: 'white',
                          '&:hover': {
                            bgcolor: 'success.dark',
                          },
                        },
                      },
                    }}
                  >
                    <ToggleButton value="monthly">Monthly</ToggleButton>
                    <ToggleButton value="yearly">Yearly</ToggleButton>
                  </ToggleButtonGroup>
                </Paper>
              </Box>

              {/* Pricing Table */}
              <TableContainer
                component={Paper}
                elevation={0}
                sx={{ border: 1, borderColor: 'divider', borderRadius: 2 }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: '20%', height: '140px' }} />
                      {pricingPlans.map((plan, index) => (
                        <TableCell
                          key={plan.name}
                          sx={{
                            width: '20%',
                            p: 2,
                            verticalAlign: 'top',
                          }}
                        >
                          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                            {plan.icon}
                            <Typography variant="h6">{plan.name}</Typography>
                          </Box>
                          <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
                            {plan.price}
                          </Typography>
                          <LoadingButton
                            loading={loadingGenerateLink || loading || subscriptionLoading}
                            disabled={
                              subscriptionLoading ||
                              basicBillingInfo?.productName?.toLocaleLowerCase() ===
                                plan.name.toLocaleLowerCase()
                            }
                            onClick={async () => {
                              setLoadingGenerateLink(true);
                              // open link in a new tab
                              if (!basicBillingInfo) {
                                const stripeApi = new StripeApi();
                                const generatedLinkResponse =
                                  await stripeApi.apiStripeGeneratePaymentLinkPost({
                                    product: plan.name,
                                  });
                                setLoadingGenerateLink(false);
                                window.location.href = generatedLinkResponse.data;
                              } else {
                                dispatch(updateSubscriptionPlan(plan.name, enqueueSnackbar));
                                setLoadingGenerateLink(false);
                              }
                            }}
                            fullWidth
                            variant="outlined"
                            color="success"
                            sx={{
                              borderRadius: 1,
                              textTransform: 'none',
                            }}
                          >
                            {getPlanStatus(plan.name, index)}
                          </LoadingButton>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ bgcolor: 'grey.50', fontWeight: 'bold' }}>
                        AI Agent Management
                      </TableCell>
                      <TableCell colSpan={4} sx={{ bgcolor: 'grey.50' }} />
                    </TableRow>
                    <TableRow>
                      <TableCell>AI Agents</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-agents`}>{plan.aiAgents}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ bgcolor: 'grey.50' }}>Deployments</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-deployments`} sx={{ bgcolor: 'grey.50' }}>
                          {plan.deployments}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell>Form Agent Interface</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-form`}>
                          <FeatureIndicator available={plan.formInterface} />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ bgcolor: 'grey.50' }}>Chat Agent Interface</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-chat`} sx={{ bgcolor: 'grey.50' }}>
                          <FeatureIndicator available={plan.chatInterface} />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell>Embed on unlimited websites</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-embed`}>
                          <FeatureIndicator available={plan.embedWebsites} />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ bgcolor: 'grey.50' }}>
                        Remove &quot;Powered by Kuverto&quot; Branding
                      </TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-branding`} sx={{ bgcolor: 'grey.50' }}>
                          <FeatureIndicator available={plan.removeBranding} />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell>Custom Domain</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-domain`}>
                          <FeatureIndicator available={plan.customDomain} />
                        </TableCell>
                      ))}
                    </TableRow>

                    {/* Agentic Workflows Section */}
                    <TableRow>
                      <TableCell sx={{ bgcolor: 'grey.50', fontWeight: 'bold' }}>
                        Agentic Workflows
                      </TableCell>
                      <TableCell colSpan={4} sx={{ bgcolor: 'grey.50' }} />
                    </TableRow>
                    <TableRow>
                      <TableCell>AI Flows</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-ai-flows`}>{plan.aiFlows}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ bgcolor: 'grey.50' }}>AI Flow Runs/Month</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-flow-runs`} sx={{ bgcolor: 'grey.50' }}>
                          {plan.aiFlowRuns}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell>Prompt Templates</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-templates`}>{plan.promptTemplates}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ bgcolor: 'grey.50' }}>Custom Actions</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-actions`} sx={{ bgcolor: 'grey.50' }}>
                          {plan.customActions}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell>Version Control</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-version`}>
                          <FeatureIndicator available={plan.versionControl} />
                        </TableCell>
                      ))}
                    </TableRow>

                    {/* Access Control Section */}
                    <TableRow>
                      <TableCell sx={{ bgcolor: 'grey.50', fontWeight: 'bold' }}>
                        Access Control
                      </TableCell>
                      <TableCell colSpan={4} sx={{ bgcolor: 'grey.50' }} />
                    </TableRow>
                    <TableRow>
                      <TableCell>User Count</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-users`}>{plan.userCount}</TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ bgcolor: 'grey.50' }}>Role Based Access Control</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-rbac`} sx={{ bgcolor: 'grey.50' }}>
                          <FeatureIndicator available={plan.roleBasedAccess} />
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell>Multi Factor Authentication</TableCell>
                      {pricingPlans.map((plan) => (
                        <TableCell key={`${plan.name}-mfa`}>
                          <FeatureIndicator available={plan.multiFactor} />
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Scrollbar>
        </DialogContent>
      </Dialog>
    </>
  );
};
