import { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  TextField, 
  Button, 
  Box, 
  Typography, 
  IconButton,
  Link,
  Divider
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

interface LoginDialogProps {
  open: boolean;
  onClose: () => void;
}

type FormType = 'login' | 'register' | 'forgot-password';

export function AgentLogicDialog({ open, onClose }: LoginDialogProps) {
  const [formType, setFormType] = useState<FormType>('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // if (formType === 'login') {
    //   console.log('Login attempt with:', { email, password });
    //   toast({
    //     title: "Login functionality coming soon",
    //     description: "This feature is currently under development.",
    //   });
    // } else if (formType === 'register') {
    //   console.log('Register attempt with:', { fullName, email, password, confirmPassword });
    //   toast({
    //     title: "Registration functionality coming soon",
    //     description: "This feature is currently under development.",
    //   });
    // } else {
    //   console.log('Password reset attempt for:', { email });
    //   toast({
    //     title: "Password reset coming soon",
    //     description: "This feature is currently under development.",
    //   });
    // }
  };

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setFullName('');
    setConfirmPassword('');
  };

  const showActionLabel = () => { // {formType === 'login' ? 'Sign In' : formType === 'register' ? 'Create Account' : 'Reset Password'}
    switch (formType) {
      case 'login':
        return 'Sign In';
      case 'register':
        return 'Create Account';
      case 'forgot-password':
        return 'Reset Password';
        default:
        return '';
    }
    return '';
  }

  const handleFormTypeChange = (type: FormType) => {
    resetForm();
    setFormType(type);
  };

  const getFormTitle = () => {
    switch (formType) {
      case 'register':
        return 'Create Account';
      case 'forgot-password':
        return 'Reset Password';
      default:
        return 'Sign In';
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        pb: 1
      }}>
        <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
          {getFormTitle()}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          {formType === 'register' && (
            <TextField
              margin="normal"
              required
              fullWidth
              id="fullName"
              label="Full Name"
              name="fullName"
              autoComplete="name"
              autoFocus
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              sx={{ mb: 2 }}
            />
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus={formType !== 'register'}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          {formType !== 'forgot-password' && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete={formType === 'register' ? 'new-password' : 'current-password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ mb: formType === 'register' ? 2 : 3 }}
            />
          )}
          {formType === 'register' && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{ mb: 3 }}
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mb: 3,
              py: 1.2,
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 500,
              borderRadius: 1.5
            }}
          >
            {showActionLabel()}
          </Button>
          <Divider sx={{ mb: 3 }}>
            <Typography variant="body2" color="text.secondary">
              or
            </Typography>
          </Divider>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            {formType === 'login' ? (
              <>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => handleFormTypeChange('register')}
                  sx={{ textDecoration: 'none' }}
                >
                  Create new account
                </Link>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => handleFormTypeChange('forgot-password')}
                  sx={{ textDecoration: 'none' }}
                >
                  Forgot password?
                </Link>
              </>
            ) : (
              <Link
                component="button"
                variant="body2"
                onClick={() => handleFormTypeChange('login')}
                sx={{ textDecoration: 'none', margin: '0 auto' }}
              >
                Back to Sign In
              </Link>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}