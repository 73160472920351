import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions,
    Box,
    TextField,
    Button
  } from "@mui/material";
  import { useState } from "react";
  
  interface OtherFeedbackDialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onSubmit: (reason: string) => void;
  }
  
  export function OtherFeedbackDialog({
    open,
    onOpenChange,
    onSubmit,
  }: OtherFeedbackDialogProps) {
    const [feedback, setFeedback] = useState("");
  
    const handleSubmit = () => {
      if (feedback.trim()) {
        onSubmit(feedback);
        onOpenChange(false);
        setFeedback("");
      }
    };
  
    return (
      <Dialog 
        open={open} 
        onClose={() => onOpenChange(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Other Feedback</DialogTitle>
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <TextField
              multiline
              rows={4}
              fullWidth
              placeholder="Please tell us why this response wasn't helpful..."
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            variant="outlined" 
            onClick={() => onOpenChange(false)}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <Button 
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }