import { ThumbsUp, ThumbsDown, Copy, X } from 'lucide-react';
import { Box, Button, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { toast } from 'sonner';
import { useBoolean } from 'src/hooks/use-boolean';
import { useState } from 'react';
import { OtherFeedbackDialog } from './OtherFeedbackDialog';
import { AgentUsersInputsApi } from 'src/api';

interface MessageFeedbackProps {
  messageContent: string;
  userInputId?: number;
  darkMode?: boolean;
}

export function MessageFeedback({ messageContent, userInputId, darkMode }: MessageFeedbackProps) {
  const feedbackOptions = [
    "Shouldn't have used Memory",
    "Don't like the style",
    'Not factually correct',
    "Didn't fully follow instructions",
    "Refused when it shouldn't have",
  ];

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [feedback, setFeedback] = useState<'positive' | 'negative' | null>(null);
  const isOtherDialogOpen = useBoolean(false);
  const handleFeedback = (type: 'positive' | 'negative') => {
    toast.success('Thank you for your feedback!', {
      description: 'You rated this message as helpful.',
    });
    // toast({
    //   title: "Thank you for your feedback!",
    //   description: `You rated this message as ${type === 'positive' ? 'helpful' : 'not helpful'}.`,
    // });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(messageContent);
      toast.success('Copied to clipboard', {
        description: 'Message content has been copied to your clipboard.',
      });
    } catch (err) {
      toast.error('Failed to copy', {
        description: 'Could not copy the message to clipboard.',
      });
    }
  };

  const handlePositiveFeedback = async () => {
    if (feedback === 'positive') {
      return;
    }
    setFeedback('positive');
    const userAgentInputApi = new AgentUsersInputsApi();
    await userAgentInputApi.apiAgentUsersInputsAgentUserInputFeedbackPut({
      feedback: 'Helpful.',
      userInputId,
    });
    console.log('user input ID', userInputId);
    toast.success('Thank you for your feedback!', {
      description: 'You rated this message as helpful.',
    });
  };

  const handleNegativeFeedback = async (reason: string) => {
    setFeedback('negative');
    setAnchorEl(null);
    const userAgentInputApi = new AgentUsersInputsApi();
    await userAgentInputApi.apiAgentUsersInputsAgentUserInputFeedbackPut({
      feedback: `Not helpful. Reason: ${reason}`,
      userInputId,
    });
    // toast({
    //   title: "Thank you for your feedback!",
    //   description: `You rated this message as not helpful because: ${reason}`,
    // });
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 0.5,
        mt: 1,
        '& .MuiIconButton-root': {
          padding: '4px',
          color: 'text.secondary',
          '&:hover': {
            color: 'text.primary',
          },
        },
      }}
    >
      <Tooltip title="Helpful">
        <IconButton onClick={handlePositiveFeedback} size="small">
          <ThumbsUp
            width={14}
            height={14}
            style = {{
              color: darkMode ? 'white' : 'black',
            }}
            className="w-4 h-4"
            fill={feedback === 'positive' ? 'currentColor' : 'none'}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Not helpful">
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
          <ThumbsDown
            width={14}
            height={14}
            style = {{
              color: darkMode ? 'white' : 'black',
            }}
            className="w-4 h-4"
            fill={feedback === 'negative' ? 'currentColor' : 'none'}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Copy message">
        <IconButton onClick={handleCopy} size="small">
          <Copy style = {{
            color: darkMode ? 'white' : 'black',
          }} width={14} height={14} />
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: 320, p: 0 }}>
          <Box
            sx={{
              px: 2,
              py: 1.5,
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body2" fontWeight="medium">
              Tell us more:
            </Typography>
            <IconButton size="small" onClick={() => setAnchorEl(null)}>
              <X className="h-4 w-4" />
            </IconButton>
          </Box>
          <Box sx={{ p: 1 }}>
            {feedbackOptions.map((option) => (
              <Button
                key={option}
                fullWidth
                sx={{
                  justifyContent: 'flex-start',
                  px: 2,
                  py: 1.5,
                  textTransform: 'none',
                  color: 'text.primary',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
                onClick={() => handleNegativeFeedback(option)}
              >
                {option}
              </Button>
            ))}
            <Button
              fullWidth
              sx={{
                justifyContent: 'flex-start',
                px: 2,
                py: 1.5,
                textTransform: 'none',
                color: 'text.primary',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
              onClick={() => {
                setAnchorEl(null);
                isOtherDialogOpen.onTrue();
              }}
            >
              Other
            </Button>
          </Box>
        </Box>
      </Popover>
      <OtherFeedbackDialog
        open={isOtherDialogOpen.value}
        onOpenChange={isOtherDialogOpen.onToggle}
        onSubmit={handleNegativeFeedback}
      />
    </Box>
  );
}
