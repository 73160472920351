import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { createNewVersionAiFlow } from 'src/redux/slices/aiflows';
import { useDispatch, useSelector } from 'src/redux/store';

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function AiFlowNewVersionDialog({ onClose, open }: Props) {
  const selectedAiFlow = useSelector((state) => state.aiFlows.selectedAiFlow);
  const selectedAiFlowVersion = useSelector((state) => state.aiFlows.selectedAiFlowVersion);
  const [versionName, setVersionName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [newVersion, setNewVersion] = React.useState(1);
  const handleClose = () => {
    onClose();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedAiFlow && selectedAiFlow.aiFlowVersions && selectedAiFlow.aiFlowVersions.length > 0) {
      // get version with maximum Version number
      const maxVersion = selectedAiFlow!.aiFlowVersions?.reduce((prev, current) =>
        (prev.version as number) > (current.version as number) ? prev : current
      );
    
      setNewVersion((maxVersion?.version ?? 1) + 0.1);
    }
  }, [selectedAiFlow]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>Version Info</span>
          <IconButton onClick={handleClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ typography: 'body2', overflow: 'hidden', p: 3 }}>
        <Stack direction="column" alignItems="center" spacing={3}>
          <TextField
            disabled
            margin="dense"
            id="name"
            value={selectedAiFlow?.name}
            label="Flow Name"
            type="email"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            disabled
            value={selectedAiFlowVersion?.version?.toFixed(1)}
            id="currentVersion"
            label="Base on Version"
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            disabled
            value={newVersion.toFixed(1)}
            id="version"
            label="New Version"
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="versionName"
            value={versionName}
            onChange={(e) => setVersionName(e.target.value)}
            label="New Version Name"
            type="email"
            fullWidth
            rows={2}
            variant="standard"
          />
          <TextField
            margin="dense"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="Description(Optional)"
            type="email"
            fullWidth
            rows={2}
            variant="standard"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={!versionName}
          onClick={() => {
            dispatch(
              createNewVersionAiFlow(
                {
                  aiFlowVersion: {
                    version: newVersion,
                    name: versionName,
                    description,
                    aiFlowId: selectedAiFlow?.id as number,
                  },
                },
                enqueueSnackbar
              )
            );

            onClose();
          }}
        >
          Create New Version
        </Button>
      </DialogActions>
    </Dialog>
  );
}
