// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_MARKETING = {
  root: ROOTS_DASHBOARD,
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  integrations: {
    root: '/integrations',
  },
  emailContacts: {
    root: path(ROOTS_DASHBOARD, '/contacts'),
  },
  team:{
    root: '/team',
  },
  pages: {
    root: '/',
    analytics: '/analytics',
    aiAgents: '/ai-agents',
    aiAgent: (id: number) => `/ai-agents/${id}`,
    aiAgentSetup: (id: number) => `/ai-agents/setup/${id}`,
    aiAssistants: '/ai-assistants',
    campaigns: '/campaigns',
    editCampaign: (id: string,provider: string) => `/marketing/campaigns/edit/${id}?provider=${provider}`,
    abtesting: '/abtesting',
    newCampaign: (campaignType: string) => `/campaigns/new/?type=${campaignType}`,
    abtestingManagement: '/management',
    abtestingResults: '/results',
    templates: '/templates',
    newTemplate: '/templates/new',
    audience: '/audience',
    products: '/products',
    editTemplate: (id: number) => `/templates/edit/${id}`,
    content: '/content',
    actions: '/actions',
    personas: '/personas',
    expressions: '/expressions',
    customActions: '/custom-actions',
    newPersona: '/personas/new',
    editPersona: (id: number) => `/personas/edit/${id}`,
    generatedContent: '/content/generated',
    outputs: '/outputs',
    deployed: '/deployed-apps',
    saved: '/saved',
    aiFlows: '/ai-workflows',
    aiFlowsFolder: (folder:string) =>  `/ai-workflows?folder=${folder}`,
    aiFlowsTemplates: (templateGroup:string) =>  `/templates?group=${templateGroup}`,
    aiFlowsRuns: '/runs',
    newAiFlow: (type?: string) =>  type ? `/ai-workflows/new?type=${type}`: `/ai-workflows/new`,
    editAiFlow: (id: number) => `/ai-workflows/edit/${id}`,
    editAiFlowVersion: (id: number,version: string) => `/ai-workflows/edit/${id}?version=${version}`,
    configurations: '/configurations',
    fineTunedModels: '/fine-tuned-models',
    howTo: '/how-to',
    faq: '/faq',
    performance: '/performance',
    abTesting: '/abTesting',
    prompts: '/prompts',
    genAI: '/gen-ai',
    knowledgeBases: '/knowledge-bases',
    agentUsersInputs: '/agent-users-inputs',
    agentUsers: '/agent-users',
    agentActivity: '/agent-activity',
    translate: '/actions/transform/translate',
    refine: '/actions/transform/refine',
    analyzeAudience: '/actions/analyze/audience',
    analyzeContent: '/actions/analyze/content',
    analyzeSmsContent: '/actions/analyze/sms-content',
    tailorMessage: '/actions/generate/tailor',
    tailor: '/actions/generate/tailor',
    generateEmailContent: '/actions/generate/email-content',
    generateSmsContent: '/actions/generate/sms-content',
    generateIdeas: '/actions/generate/ideas',
    generateImg: '/actions/generate/image',
    generatePrompts: '/actions/generate/prompts',
    copywriting: '/actions/generate/copywriting',
    editGeneratedContent: (id: number) => `/content/generated/edit/${id}`,
    automation: '/automation',
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
