import {
  Dialog,
  DialogActions,
  Button,
  MenuItem,
  TextField,
  DialogTitle,
  DialogContent,
  Typography,
  FormControlLabel,
  Switch,
  Paper,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { RHRadioGroup } from 'src/components/hook-form/RHFRadioGroup';
import { ConfigurationLogic, ConnectionLogic, InputDataLogic } from 'src/api';
import Iconify from 'src/components/iconify';
import MenuPopover from 'src/components/menu-popover';
import ModelSelect from '../model/ModelSelect';
import { useDispatch, useSelector } from 'src/redux/store';
import { upsertConfiguration } from 'src/redux/slices/configurations';
import { useSnackbar } from '../snackbar';
import SaveConfigurationsDialog from './SaveConfigurationsDialog';
import { useBoolean } from 'src/hooks/use-boolean';
import ConfigurationSelect from './ConfigurationSelect';
import { LoadingButton } from '@mui/lab';
import _, { set } from 'lodash';
import * as Yup from 'yup';
import { updateSelectedAiFlowItem, updateTempSelectedAiFlowItem } from 'src/redux/slices/aiflows';
import { DefaultMaxTokens } from 'src/common/constants/content.constants';
import { KuvertoSlider } from '../slider/KuvertoSlider';
import ConnectionSelect from '../connection/ConnectionSelect';
import {
  ModelType,
  ModelTypeMaxTokens,
  findIntegrationByModelType,
} from 'src/common/constants/llm-models.constants';
import PreviousActionsSelect from '../previous-actions/PreviousActionsSelect';
import PromptEditor from '../prompt-editor/PromptEditor';
import FormProvider from '../hook-form/FormProvider';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ModelProviderSelect from '../model/ModelProviderSelect';

interface Props {
  onClose: () => void;
  open: boolean;
  newConfiguration?: boolean;
  inputConfiguration?: ConfigurationLogic;
  onConfirmConfiguration?: (configuration: ConfigurationLogic) => void;
}
export default function ConfigurationDialog({
  onClose,
  open,
  newConfiguration,
  onConfirmConfiguration,
  inputConfiguration,
}: Props) {
  const [saveNew, setSaveNew] = useState<boolean>(false);
  const [loopReferenceKeyword, setLoopReferenceKeyword] = useState<string>('item');
  const [selectedModel, setSelectedModel] = useState<string>(ModelType.gpt_4_o_mini);
  const [selectedConnectionId, setSelectedConnectionId] = useState<number | null>(0);
  const [selectedConnection, setSelectedConnection] = useState<ConnectionLogic>();
  const [selectedLoopCount, setSelectedLoopCount] = useState<Number>(1);
  const [selectedLoopSource, setSelectedLoopSource] = useState<string>();
  const [modelProvider, setSelectedModelProvider] = useState<string>('OpenAI' as string);
  const [maxTokens, setMaxTokens] = useState<number | string>(DefaultMaxTokens);
  const [loop, setLoop] = useState<boolean>(false);

  const [temperature, setTemperature] = useState<number>(1);
  const [selectedConfiguration, setSelectedConfiguration] = useState<ConfigurationLogic>();
  const [withAiExpressions, setWithAIExpressions] = useState<boolean>(false);
  const [lastModifiedConfiguration, setLastModifiedConfiguration] = useState<ConfigurationLogic>();
  const loading = useSelector((state) => state.configurations.loading);
  const configurations = useSelector((state) => state.configurations.configurations);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const dispatch = useDispatch();
  const runsMode = useSelector((state) => state.aiFlows.runsMode);

  const [iterationTypes, setIterationTypes] = useState([
    { label: 'Source', value: 'Source' },
    { label: 'Count', value: 'Count' },
  ]);

  const [selectedModelSelection, setSelectedModelSelection] = useState<string>('Models');

  const [modelSelection , setModelSelection] = useState([
    { label: 'Models', value: 'Models' },
    { label: 'Custom Model Name', value: 'Custom Model Name' },
  ]);

  const temperatureMarks = [
    {
      value: 0,
    },
    {
      value: 0.25,
    },
    {
      value: 0.5,
    },
    {
      value: 0.75,
    },
    {
      value: 1,
    },
    {
      value: 1.25,
    },
    {
      value: 1.5,
    },
    {
      value: 1.75,
    },
    {
      value: 2,
    },
  ];

  const calculateStep = (selModel: string) => {
    const tempSelectedModel = selModel.startsWith('ft:') ? selModel.split(':')[1] : selModel;
    return ModelTypeMaxTokens[tempSelectedModel as keyof typeof ModelTypeMaxTokens] > 33000
      ? 500
      : 50;
  };

  const calculateMarks = (selModel: string) => {
    const tempSelectedModel = selModel.startsWith('ft:') ? selModel.split(':')[1] : selModel;
    return Array.from(
      {
        length:
          ModelTypeMaxTokens[tempSelectedModel as keyof typeof ModelTypeMaxTokens] > 33000
            ? ModelTypeMaxTokens[tempSelectedModel as keyof typeof ModelTypeMaxTokens] / 500
            : ModelTypeMaxTokens[tempSelectedModel as keyof typeof ModelTypeMaxTokens] / 50,
      },
      (i, index) => ({
        value:
          ModelTypeMaxTokens[tempSelectedModel as keyof typeof ModelTypeMaxTokens] > 33000
            ? index * 500
            : index * 50,
      })
    );
  };

  const calculateMax = (selModel: string) => {
    const tempSelectedModel = selModel.startsWith('ft:') ? selModel.split(':')[1] : selModel;
    return ModelTypeMaxTokens[tempSelectedModel as keyof typeof ModelTypeMaxTokens];
  };

  const connections = useSelector((state) => state.connection.connections);

  // useEffect(() => {
  //   // if (selectedConnection !== 0){
  //   //   return;
  //   // }
  //   if (connections) {
  //     const tmpconnections = connections.filter(
  //       (connection: any) => connection.integrationName === modelProvider
  //     );
  //     console.log('connections', connections);

  //     const tmpSelectedConnection = tmpconnections.find((connection: any) => connection.default);
  //     if (!tmpSelectedConnection) {
  //       return;
  //     }
  //     if (
  //       !_.isEqual(
  //         tmpSelectedConnection,
  //         selectedConnection && !_.isEqual(tmpSelectedConnection.id, selectedConnectionId)
  //       )
  //     ) {
  //       console.log('tmpSelectedIntegration', tmpSelectedConnection);
  //       console.log('selectedConnection', selectedConnection);
  //       setSelectedConnectionId(tmpSelectedConnection.id as number);
  //       setSelectedConnection(tmpSelectedConnection);
  //     }

  //     // if (tmpSelectedIntegration && !selectedConnectionInput) {
  //     //
  //     //   onConnectionSelect(tmpSelectedIntegration?.id as number);
  //     // }
  //   }
  // }, [
  //   connections,
  //   selectedConnection,
  //   selectedConnectionId,
  //   modelProvider,
  //   selectedModel,
  //   selectedItem,
  // ]);

  useEffect(() => {
    if (open && selectedItem && selectedItem.inputData) {
      const inputData = selectedItem.inputData as InputDataLogic;
      setSelectedConfiguration(inputData?.actionInputData?.configuration);
    } else {
      // clearForm();
    }
    if (selectedItem) {
      if (selectedItem.header === 'GenerateImage') {
        setSelectedModel(ModelType.dall_e_3);
      }
    }
  }, [selectedItem, open]);

  const [selectedToneOfVoiceAiModel, setSelectedToneOfVoiceAiModel] = useState<string>('Tone');
  const [selectedIterationType, setSelectedIterationType] = useState<string>('Source');

  const handleClose = () => {
    clearForm();
    onClose();
  };
  const clearForm = () => {
    setSelectedModel(ModelType.gpt_4_o_mini);
    setSelectedConnectionId(0);
    setMaxTokens(DefaultMaxTokens);
    setTemperature(1);
    setSelectedConfiguration(undefined);
  };
  const [targetText, setTargetText] = useState<string>('');
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const defaultValues = {
    selectedSource: '',
  };

  const ConfigurationDialogSchema: any = Yup.object().shape({
    // selectedSource: Yup.string().required('Required'),
  });

  const methods = useForm<any>({
    resolver: yupResolver(ConfigurationDialogSchema),
    mode: 'all',
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    trigger,
  } = methods;

  useEffect(() => {
    const mp = selectedConfiguration?.modelProvider ?? 'OpenAI';
    const tmpconnections = connections.filter(
      (connection: any) => connection.integrationName === mp
    );
    const tmpSelectedConnection = tmpconnections.find((connection: any) => connection.default);
    setSelectedConnectionId(selectedConfiguration?.connectionId ?? tmpSelectedConnection?.id ?? 0);
    if (selectedConfiguration) {
      const tmpModel =
        selectedItem?.header === 'GenerateImage' ? ModelType.dall_e_3 : ModelType.gpt_4_o_mini;
      setSelectedModel(selectedConfiguration.model ?? tmpModel);
      setSelectedModelSelection(selectedConfiguration.modelSelection ?? 'Models');
      setSelectedModelProvider(mp);

      setMaxTokens(selectedConfiguration.maxTokens ?? DefaultMaxTokens);
      setTemperature(selectedConfiguration.temperature ?? 1);
      if (selectedConfiguration.loopSettings) {
        setLoop(true);
        setSelectedLoopCount(selectedConfiguration.loopSettings.count as number);
        setSelectedLoopSource(selectedConfiguration.loopSettings.source as string);
        methods.setValue('selectedSource', selectedConfiguration.loopSettings.source as string);
        methods.trigger();
        setLoopReferenceKeyword(selectedConfiguration.loopSettings.referenceKeyword as string);
        setSelectedIterationType(
          (selectedConfiguration.loopSettings.loopType as string) ?? 'Source'
        );
      }
    }
  }, [selectedConfiguration, connections, selectedItem, methods, open]);

  useEffect(() => {
    if (inputConfiguration) {
      setSelectedConfiguration(inputConfiguration);
    }
  }, [inputConfiguration]);

  const openSaveConfiguration = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const dialogRootRef = useRef();

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        handleClose();
      }}
      ref={dialogRootRef as any}
      sx={{ zIndex: 2400 }}
    >
      <DialogTitle>Configurations</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 0.55 }}>
          <Stack spacing={3.5}>
            {/* <TextField
                  size="small"
                  type="number"
                  name="minWordCount"
                  value={minWordCount ?? ''}
                  onChange={(event) => {
                    console.log('wababa', parseInt(event.target.value, 10));
                    setMinWordCount(parseInt(event.target.value, 10));
                  }}
                  label="Min Word Count"
                  InputLabelProps={{ shrink: true }}
                  sx={{ maxWidth: { md: 300 } }}
                /> */}
            <ModelProviderSelect
              selectedModelInput={selectedModel}
              selectedModelProviderInput={modelProvider}
              maxWidth={300}
              onModelProviderSelect={(mp: string) => {
                setSelectedModelProvider(mp);
                setSelectedModel('');
                setSelectedConnectionId(null);
                setSelectedConfiguration({
                  ...selectedConfiguration,
                  modelProvider: mp,
                  connectionId: null,
                  model: '',
                });
              }}
            />
            {/* <ConnectionSelect
              selectedModel={selectedModel}
              selectedIntegration={modelProvider}
              selectedConnectionInput={selectedConnectionId}
              onConnectionSelect={(connectionId: number) => {
                if (!connectionId) {
                  setSelectedConnectionId(null);
                  return;
                }
                setSelectedConnectionId(connectionId);
                setSelectedConfiguration({
                  ...selectedConfiguration,
                  connectionId,
                });
              }}
              maxWidth={300}
            /> */}
             <RHRadioGroup
                  value={selectedModelSelection}
                  onChange={(event) => {
                   setSelectedModelSelection(event.target.value);
                   setSelectedConfiguration({
                      ...selectedConfiguration,
                      modelSelection: event.target.value,
                    });
                  }}
                  row
                  spacing={4}
                  name="modelSelection"
                  options={modelSelection}
                  sx={{ ml: 1, pl: 1, pt: 0, mt: '4px !important', marginBottom: '0px !important' }}
                />
                {selectedModelSelection === 'Models' && (
            <ModelSelect
            marginTop={14}
              selectedModelProviderInput={modelProvider}
              selectedConnectionInput={selectedConnectionId}
              selectedModelInput={selectedModel}
              onModeSelect={(model: string) => {
                setSelectedModel(model);
                //   setSelectedConnection(null);
                setSelectedConfiguration({
                  ...selectedConfiguration,
                  model,
                  //      connectionId: null,
                });
              }}
              maxWidth={300}
            />
)}
            {selectedModelSelection === 'Custom Model Name' && (
            <TextField
              margin="dense"
              value={selectedConfiguration?.customModelName}
              onChange={(event) => {
                setSelectedConfiguration({
                  ...selectedConfiguration,
                  customModelName: event.target.value,
                });
              }}
              sx={{ width: 300, marginTop: '10px !important' }}
              id="version"
              label="Model Name"
              helperText="Name of the custom model(or contained in the model)"
              type="text"
              fullWidth
              variant="standard"
            />
)}

            {/* <MaxWordSelect
                  maxWidth={300}
                  selectedMaxWordInput={maxWordCount as number}
                  onMaxWordSelect={(maxWord: number) => {
                    setMaxWordCount(maxWord);
                    setSelectedConfiguration({
                      ...selectedConfiguration,
                      maxWordCount: maxWord,
                    });
                  }}
                /> */}
            {selectedItem?.header !== 'GenerateImage' && (
              <>
                <Typography
                  variant="subtitle2"
                  sx={{ color: 'text.secondary', fontWeight: 'fontWeightBold' }}
                  noWrap
                >
                  Maximum Length: Maximum number of tokens to generate <u>shared</u> between prompt
                  and completion. 1 Token ≈ 4 characters.
                </Typography>
                <KuvertoSlider
                  name="maxTokenSlider"
                  valueLabelDisplay="on"
                  disabled={runsMode}
                  value={maxTokens as number}
                  onChange={(event, value) => {
                    setSelectedConfiguration({
                      ...selectedConfiguration,
                      maxTokens: value as number,
                    });
                  }}
                  step={calculateStep(selectedModel)}
                  marks={calculateMarks(selectedModel)}
                  max={calculateMax(selectedModel)}
                  min={0}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ color: 'text.secondary', fontWeight: 'fontWeightBold' }}
                  noWrap
                >
                  Creativity: Influence the randomness of the generated output. The higher the
                  temperature, the more creative the output.
                </Typography>
                <KuvertoSlider
                  name="temperatureSlider"
                  valueLabelDisplay="on"
                  disabled={runsMode}
                  onChange={(event, value) => {
                    setTemperature(value as number);
                    setSelectedConfiguration({
                      ...selectedConfiguration,
                      temperature: value as number,
                    });
                  }}
                  value={temperature as number}
                  step={0.25}
                  marks={temperatureMarks}
                  max={2}
                  min={0}
                />
              </>
            )}
            {!newConfiguration && (
            <FormControlLabel
              control={
                <Switch
                  value={loop}
                  onChange={(event) => {
                    setLoop(event.target.checked);
                  }}
                  checked={loop}
                  disabled={runsMode}
                  disableTouchRipple
                />
              }
              label="Loop"
              sx={{ flexGrow: 1, m: 0 }}
            />
            )}

            {loop &&  (
              <>
                <RHRadioGroup
                  value={selectedIterationType}
                  onChange={(event) => {
                    setSelectedIterationType(event.target.value);
                  }}
                  row
                  spacing={4}
                  name="iterationType"
                  options={iterationTypes}
                  sx={{ ml: 2, pl: 1.5, pt: 0, mt: '4px !important' }}
                />
                {selectedIterationType === 'Source' && (
                  <>
                    <FormProvider methods={methods}>
                      <PreviousActionsSelect
                        name="selectedSource"
                        key="selectedSource"
                        selectedSourceInput={selectedLoopSource}
                        maxWidth={300}
                        onPreviousActionsSelect={(selectedSource: string) => {
                          setValue('selectedSource', selectedSource);
                          setSelectedLoopSource(selectedSource);
                        }}
                      />
                    </FormProvider>
                    <Paper
                      key=""
                      variant="outlined"
                      sx={{
                        p: 1,
                        display: 'flex',
                        position: 'relative',
                        flexDirection: 'column',
                        height: '100%',
                        width: 300,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ textAlign: 'center', paddingBottom: 1 }}
                      >
                        Loop Item Reference
                      </Typography>
                      <PromptEditor
                        height={40}
                        reference
                        promptInput={loopReferenceKeyword}
                        setPromptInput={(newValue) => {
                          setLoopReferenceKeyword(newValue);
                        }}
                      />
                    </Paper>
                  </>
                )}
                {selectedIterationType === 'Count' && (
                  <TextField
                    margin="dense"
                    value={selectedLoopCount}
                    onChange={(event) => {
                      setSelectedLoopCount(Number(event.target.value));
                    }}
                    sx={{ width: 300 }}
                    id="version"
                    label="Iterations Count"
                    type="number"
                    fullWidth
                    variant="standard"
                  />
                )}
              </>
            )}
            {/* {withAiExpressions && (
              <>
                <RHRadioGroup
                  value={selectedToneOfVoiceAiModel}
                  onChange={(event) => {
                    setSelectedToneOfVoiceAiModel(event.target.value);
                  }}
                  row
                  spacing={4}
                  name="toneOfVoiceOptions"
                  options={toneOfVoiceAiModelsOptions}
                  sx={{ ml: 2, pl: 1.5, pt: 0, mt: '4px !important' }}
                />
              </>
            )} */}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack
          sx={{ width: '100%' }}
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          {!newConfiguration && (
            <ConfigurationSelect
              minWidth={100}
              maxWidth={200}
              inputConfiguration={selectedConfiguration}
              onConfigurationSelect={(configId) => {
                const config = configurations.find((c) => c.id === configId);
                setSelectedConfiguration(_.cloneDeep(config) as ConfigurationLogic);
              }}
            />
          )}
          <Stack
            sx={{ pt: 1 }}
            display="flex"
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Button
              sx={{
                minWidth: { md: 130 },
                maxHeight: { md: 40 },
                mr: 2,
              }}
              variant="outlined"
              color="inherit"
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Stack display="flex" direction="row" spacing={2}>
              {/* <Button
                disabled={runsMode}
                sx={{
                  minWidth: { md: 130 },
                  maxHeight: { md: 40 },
                  zIndex: 2600,
                }}
                variant="outlined"
                color="inherit"
                startIcon={<Iconify icon="eva:more-vertical-fill" />}
                onClick={(event) => handleOpenPopover(event)}
              >
                More
              </Button> */}
              <LoadingButton
                variant="outlined"
                loading={loading}
                disabled={
                  runsMode ||
                  (!!selectedConfiguration?.id &&
                    _.isEqual(
                      selectedConfiguration,
                      configurations?.find((c) => c.id === selectedConfiguration?.id)
                    ))
                }
                size="large"
                color="inherit"
                onClick={() => {
                  const config: ConfigurationLogic = {
                    model: selectedModel,
                    maxTokens: (maxTokens as number) ?? undefined,
                    connectionId: selectedConnectionId,
                    temperature: temperature ?? undefined,
                    id: selectedConfiguration?.id,
                    name: selectedConfiguration?.name,
                    loopSettings: loop
                      ? {
                          referenceKeyword: loopReferenceKeyword,
                          count: selectedLoopCount as number,
                          source: selectedLoopSource,
                          loopType: selectedIterationType,
                        }
                      : undefined,
                  };
                  setLastModifiedConfiguration(config);
                  openSaveConfiguration.onTrue();
                }}
                sx={{
                  minWidth: { md: 130 },
                  maxHeight: { md: 40 },
                }}
              >
                Save
              </LoadingButton>
              {!newConfiguration && (
                <Button
                  variant="outlined"
                  size="large"
                  disabled={runsMode || !selectedModel} // || !selectedConnection}
                  onClick={() => {
                    const tmpConfigurations: ConfigurationLogic = {
                      model: selectedModel,
                      modelProvider,
                      connectionId: selectedConnectionId,
                      maxTokens: (maxTokens as number) ?? undefined,
                      temperature: temperature ?? undefined,
                      name: selectedConfiguration?.name,
                      loopSettings: loop
                        ? {
                            referenceKeyword: loopReferenceKeyword,
                            count: selectedLoopCount as number,
                            source: selectedLoopSource,
                            loopType: selectedIterationType,
                          }
                        : undefined,
                    };
                    const config = { ...selectedConfiguration, ...tmpConfigurations };
                    if (onConfirmConfiguration) {
                      onConfirmConfiguration(config);
                    } else if (selectedItem) {
                      const newItem = {
                        ...selectedItem,
                        inputData: {
                          ...selectedItem.inputData,
                          actionInputData: {
                            ...selectedItem.inputData?.actionInputData,
                            configuration: config,
                          },
                        },
                      };
                      dispatch(updateTempSelectedAiFlowItem(newItem));
                    }
                    handleClose();
                  }}
                  sx={{
                    minWidth: { md: 130 },
                    maxHeight: { md: 40 },
                  }}
                >
                  Confirm
                </Button>
              )}
              <MenuPopover
                open={openPopover}
                anchorEl={openPopover}
                onClose={handleClosePopover}
                arrow="right-top"
                sx={{ zIndex: 2520 }}
              >
                <MenuItem
                  onClick={() => {
                    setSaveNew(true);
                    openSaveConfiguration.onTrue();
                  }}
                  sx={{ zIndex: 2520 }}
                >
                  <Iconify icon="eva:plus-fill" />
                  Save New
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    clearForm();
                  }}
                  sx={{ zIndex: 2520 }}
                >
                  <Iconify icon="carbon:reset" />
                  Reset
                </MenuItem>
              </MenuPopover>
            </Stack>
          </Stack>
        </Stack>
      </DialogActions>
      <SaveConfigurationsDialog
        saveNew={saveNew}
        configuration={lastModifiedConfiguration}
        open={openSaveConfiguration.value}
        onClose={openSaveConfiguration.onFalse}
      />
    </Dialog>
  );
}
