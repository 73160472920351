import { Card, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState, useRef, useEffect } from 'react';
import { AgentApi, AgentLogic, AssistantInfo, DeployedAgentLogic, GenerativeAiApi } from 'src/api';
import Scrollbar from 'src/components/scrollbar';
import { useSelector } from 'src/redux/store';
import ChatInput from '../ChatInput';
import ChatInputPublic from './ChatInputPublic';
import ChatMessagePublic from './ChatMessagePublic';
import { useNavigate } from 'react-router';
import Page404 from 'src/pages/Page404';
import AgentNotFound from './AgentNotFound';
import { ShareButton } from 'src/common/share/ShareButton';
import { darkModeColor } from './DarkModeColor';
import Smartlook from 'smartlook-client';
import { fileToBase64 } from 'src/utils/fileUtils';
import { Power } from 'lucide-react';
import Logo from 'src/components/logo';

interface Params {
  deployedAgent?: DeployedAgentLogic;
}

interface Message {
  sender: 'user' | 'assistant';
  content: string;
  userInputId?: number;
  fileTypes?: string[];
  fileNames?: string[];
}

export default function AgentChatUiPublic({ deployedAgent }: Params) {
  const [layout, setLayout] = useState<string>('fullwidth');
  const [selectedAgent, setSelectedAgent] = useState<DeployedAgentLogic>();
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [threadId, setThreadId] = useState<string>();
  const [agentNotFound, setAgentNotFound] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState<string>('#FFFFFF');
  const [assistantInfo, setAssistantInfo] = useState<AssistantInfo>();
  const [messages, setMessages] = useState<Message[]>([
    {
      sender: 'assistant',
      content: 'How can I assist you in finding information or solving a problem today?',
    },
  ]);
  if (process.env.NODE_ENV === 'production') {
    // Smartlook.init(process.env.SMARTLOOK_KEY);
    Smartlook.init('c86e9beab40a477573dc7c2f257819be7d72f6a6');
  }
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  useEffect(() => {
    if (deployedAgent) {
      setSelectedAgent(deployedAgent);
    }
  }, [deployedAgent]);

  useEffect(() => {
    const stopPropagation = (e: Event) => {
      e.stopPropagation(); // Prevent scroll event from bubbling up
    };

    window.addEventListener('wheel', stopPropagation, { passive: false });
    window.addEventListener('touchmove', stopPropagation, { passive: false });

    return () => {
      window.removeEventListener('wheel', stopPropagation);
      window.removeEventListener('touchmove', stopPropagation);
    };
  }, []);

  useEffect(() => {
    if (messages.length > 1) {
      scrollToBottom();
    }
  }, [messages, isLoading]); // Scroll when messages change or loading state changes

  const handleSend = async (message: string, attachments?: File[]) => {
    let fileTypes: string[] | undefined;
    let fileNames: string[] | undefined;
    if (attachments && attachments.length > 0) {
      fileTypes = attachments.map((file) => file.type);
      fileNames = attachments.map((file) => file.name);
    }

    const newMessages = [
      ...messages,
      {
        role: 'user' as const,
        content: message,
        timestamp: new Date(),
        fileTypes,
        fileNames,
      },
    ];
    console.log('newMessages', newMessages);
    // const newMessages = [...messages, { sender: 'user' as const, content: message }];
    setMessages(newMessages as any[]);
    setIsLoading(true);
    let attachmentsDto;
    if (attachments) {
      attachmentsDto = await Promise.all(
        attachments.map(async (file) => {
          const base64Data = await fileToBase64(file);
          return {
            fileName: file.name,
            contentType: file.type,
            base64Data,
          };
        })
      );
    }
    const generativeAiApi = new GenerativeAiApi();
    try {
      const response = await generativeAiApi.apiGenerativeAiPublicChatPost({
        input: message,
        agentId: selectedAgent?.agentId,
        threadId,
        attachments: attachmentsDto,
        configurations: selectedAgent?.engineConfigurations,
      });
      setThreadId(response.data.assistantInfo?.threadId as string);
      setMessages([
        ...newMessages,
        {
          sender: 'assistant' as const,
          content: response.data.message as string,
          userInputId: response.data.agentUserInput?.id as number,
          fileNames: [],
          fileTypes: [],
        },
      ] as any[]);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setMessages([
        ...newMessages,
        {
          sender: 'assistant',
          content: 'Sorry, I could not process your request. Please try again.',
        },
      ] as any[]);
    }

    // setTimeout(() => {
    //   setMessages([
    //     ...newMessages,
    //     {
    //       sender: 'assistant' as const,
    //       content: 'This is a demo response. The UI is just for demonstration purposes.',
    //     },
    //   ]);
    //   setIsLoading(false);
    // }, 9000);
  };

  const calculateMobileScrollbarHeightWithSuggestions = () => {
    const suggestionsLength =
      selectedAgent?.uiConfigurations?.agentChatUiConfigurations?.smartSuggestions?.length ?? 0;
    return 450 - suggestionsLength * 20;
  };

  const calculateNewScrollbarHeightWithSuggestions = () => {
    if (!selectedAgent?.uiConfigurations?.agentChatUiConfigurations?.enableSmartSuggestions) {
      return 'calc(100vh - 200px)';
    }
    let defaultHeightDiminisher = 210;
    const suggestions =
      selectedAgent?.uiConfigurations?.agentChatUiConfigurations?.smartSuggestions;
    const suggestionsHeight = suggestions ? suggestions.length * 20 : 0;
    defaultHeightDiminisher += suggestionsHeight;
    return `calc(100vh - ${defaultHeightDiminisher}px)`;
  };

  useEffect(() => {
    if (selectedAgent?.uiConfigurations?.agentChatUiConfigurations) {
      setLayout(selectedAgent.uiConfigurations.agentChatUiConfigurations?.layout ?? 'fullwidth');
      setDarkMode(selectedAgent.uiConfigurations.agentChatUiConfigurations?.darkMode ?? false);
      setBackgroundColor(
        selectedAgent.uiConfigurations.agentChatUiConfigurations?.backgroundColor ?? '#FFFFFF'
      );
      setMessages([
        {
          sender: 'assistant',
          content:
            selectedAgent.uiConfigurations.agentChatUiConfigurations?.introMessage ??
            'How can I assist you in finding information or solving a problem today?',
        },
      ]);
    }
  }, [selectedAgent]);

  const getLayoutMaxWidth = () => {
    switch (layout) {
      case 'wide':
        return '800px';
      case 'narrow':
        return '36rem';
      case 'centered':
        return '48rem';
      case 'fullwidth':
        return '100%';
      default:
        return '48rem';
    }
  };

  const getLayoutMx = () => {
    switch (layout) {
      case 'centered':
        return 'auto';
      case 'narrow':
        return 'auto';
      case 'fullwidth':
        return '0';
      default:
        return 'auto';
    }
  };

  const logo = (
    <Box
      component="div"
      sx={{
        width: 20,
        height: 20,
        display: 'inline-flex',
      }}
    >
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 230.32 230.62"
      >
        <g id="Layer_1-2" data-name="Layer 1">
          <g>
            <polygon
              className="cls-1"
              style={{ fill: '#45ce4e' }}
              points="226.75 0 189.58 39.62 120.39 113.4 87.77 148.2 65.23 172.22 32.61 206.99 22.29 196 10.07 182.96 0 172.22 32.61 137.44 55.16 113.4 87.77 78.64 161.52 0 226.75 0"
            />
            <polygon
              className="cls-1"
              style={{ fill: '#45ce4e' }}
              points="120.08 0 67.06 56.54 34.43 91.33 25.38 81.65 1.81 56.54 34.43 21.77 54.85 0 120.08 0"
            />
            <polygon
              className="cls-1"
              style={{ fill: '#45ce4e' }}
              points="230.32 230.62 165.08 230.62 110.07 171.97 127.82 153.02 142.69 137.18 230.32 230.62"
            />
            <polygon
              className="cls-1"
              style={{ fill: '#45ce4e' }}
              points="120.02 230.62 55.04 230.62 72.67 211.84 87.54 195.98 88.24 196.74 120.02 230.62"
            />
          </g>
        </g>
      </svg>
    </Box>
  );

  return agentNotFound ? (
    <AgentNotFound />
  ) : (
    <>
      <Box sx={{ position: 'fixed', top: 10, right: 20, zIndex: 10 }}>
        <ShareButton darkMode={darkMode} />
      </Box>
      <Card
        sx={{
          backgroundColor: darkMode ? darkModeColor : backgroundColor,
          p: 1,
          margin: '0px !important',
          //   minHeight: { md: 550, xl: 680 },
          //   maxHeight: { md: 550, xl: 680 },
        }}
      >
        <Box sx={{ position: 'relative', margin: '0px !important' }}>
          <Box
            sx={{
              //   minHeight: '100vh',
              //   bgcolor: '#F7F7F8',
              //     pb: '160px',
              backgroundColor: darkMode ? darkModeColor : backgroundColor,
              maxWidth: getLayoutMaxWidth(),
              mx: getLayoutMx(),
              overflowX: 'hidden !important',
              overflowY: 'hidden !important',
              minHeight: 'calc(100vh - 170px)',
              maxHeight: 'calc(100vh - 170px)',
              //   minHeight: 200,

              //   maxHeight: 500,
              //      width: getLayoutMaxWidth,
              //      minWidth: getLayoutMaxWidth,
            }}
          >
            <Scrollbar
              sx={{
                //   minHeight: 300,
                maxHeight: {
                  xs: calculateMobileScrollbarHeightWithSuggestions(),
                  xl: calculateNewScrollbarHeightWithSuggestions(),
                },
                overflowX: 'hidden !important',
                backgroundColor: darkMode ? darkModeColor : backgroundColor,
              }}
            >
              <Box
                sx={{
                  backgroundColor: darkMode ? darkModeColor : backgroundColor,
                  minWidth: '100%',
                  minHeight: '100%',
                  p: 1,
                }}
              >
                {messages.map((message, index) => (
                  <ChatMessagePublic
                    index={index}
                    key={index}
                    {...message}
                    selectedAgent={selectedAgent as DeployedAgentLogic}
                  />
                ))}
                {isLoading && (
                  <ChatMessagePublic index={0} sender="assistant" content="Loading..." />
                )}
                <div ref={messagesEndRef} />
              </Box>
            </Scrollbar>
            <ChatInput
              publicMode
              onSend={handleSend}
              disabled={isLoading}
              selectedAgent={selectedAgent as DeployedAgentLogic}
            />
          </Box>
        </Box>
      </Card>
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          left: 16,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          color: darkMode ? 'white' : '#333',
          fontSize: '0.875rem',
          zIndex: 50,
          bgcolor: darkMode ? darkModeColor : '#fff',
          py: 1.5,
          px: 3,
          borderRadius: 1,
          border: '1px solid #e0e0e0',
          boxShadow: 'none',
          cursor: 'default',
          '& .power-icon': {
            color: '#333',
            width: 16,
            height: 16,
            marginRight: 1,
          },
          '& span': {
            fontWeight: 400,
            letterSpacing: '-0.01em',
          },
        }}
      >
        {logo}
        <Typography variant="subtitle2">
          Powered by
          <Link
            href="https://kuverto.com"
            target="_blank"
            sx={{ color: '#45ce4e', textDecoration: 'none', fontWeight: 600, ml: 1 }}
          >
            Kuverto
          </Link>
        </Typography>
      </Box>
    </>
  );
}
