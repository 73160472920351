import { fetchConfigurations } from 'src/redux/slices/configurations';
import { fetchIntegrationGroups } from 'src/redux/slices/integrations';
import { fetchAiFlowsRuns } from 'src/redux/slices/aiFlow-runs';
import { fetchActionGroups, fetchCustomActions } from 'src/redux/slices/aiflows';
import { fetchOnboarding, fetchUserProfile } from 'src/redux/slices/user';
import { fetchConnections } from 'src/redux/slices/connections';
import { fetchBasicBillingInfo, fetchTiers } from 'src/redux/slices/subscription';
import { fetchFolders } from 'src/redux/slices/folders';
import { fetchCreditsStatus } from 'src/redux/slices/credits-status';
import { fetchAllNotifications } from 'src/redux/slices/notifications';
import { fetchAiAgentTemplates, fetchAiFlowTemplateGroups } from 'src/redux/slices/templates';
import { fetchPromptRepositories, fetchPrompts } from 'src/redux/slices/prompts';
import { fetchKnowledgeBases } from 'src/redux/slices/knowledgebases';
import { fetchAgents, fetchAgentsRuns } from 'src/redux/slices/agents';
import { fetchAssistants } from 'src/redux/slices/assistants';

export const InitStore = (dispatch: any) => {
  dispatch(fetchOnboarding());
  dispatch(fetchActionGroups());
  dispatch(fetchAiAgentTemplates());
  dispatch(fetchCustomActions());
  dispatch(fetchConfigurations());
  dispatch(fetchAllNotifications());
  dispatch(fetchIntegrationGroups());
  dispatch(fetchConnections());
  dispatch(fetchAgents());
  dispatch(fetchAssistants());
  dispatch(fetchAiFlowsRuns());
  dispatch(fetchAiFlowTemplateGroups());
  dispatch(fetchUserProfile());
  dispatch(fetchBasicBillingInfo());
  dispatch(fetchTiers());
  dispatch(fetchCreditsStatus());
  dispatch(fetchFolders());
  dispatch(fetchPromptRepositories());
  dispatch(fetchKnowledgeBases());
  dispatch(fetchAgentsRuns());

};
