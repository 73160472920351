import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { ITemplatesState } from 'src/@types/templates';
// @types
import { TemplateApi } from 'src/api';

// ----------------------------------------------------------------------

const initialState: ITemplatesState = {
  aiFlowTemplatesGroups: [],
  aiAgentTemplates: [],
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'expressions',
  initialState,
  reducers: {
    fetchAiFlowTemplatesGroupsSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.aiFlowTemplatesGroups = action.payload;
    },

    fetchAiAgentTemplatesSuccess(state, action) {
      state.loading = false;
      state.loaded = true;
      state.aiAgentTemplates = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchAiFlowTemplateGroups() {
  return async (dispatch: Dispatch) => {
    try {
      const templatesApi = new TemplateApi();
      const response = await templatesApi.apiTemplateAiflowTemplateGroupsGet();
      dispatch(slice.actions.fetchAiFlowTemplatesGroupsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchAiAgentTemplates() {
  return async (dispatch: Dispatch) => {
    try {
      const templatesApi = new TemplateApi();
      const response = await templatesApi.apiTemplateAiAgentTemplatesGet();
      dispatch(slice.actions.fetchAiAgentTemplatesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}