import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IAgentState } from 'src/@types/agent';
// @types
import { AgentApi, AgentFieldLogic, AgentLogic, AgentRunLogic, AiAgentTemplate, EmailProviderApi } from 'src/api';
import { addAiFlowRun } from './aiFlow-runs';

// ----------------------------------------------------------------------

const initialState: IAgentState = {
  agents: [],
  agentsRuns: {},
  selectedAgent: null,
  creatingAgentRun: false,
  knowledgeBaseOrAiFlowToView: { type: null, id: 0 },
  sidebarPopoverOpen: null,
  activatingAgent: false,
  hoveredAgentFieldOrder: 0,
  activeStep: 0,
  publishingAgent: false,
  selectedAgentField: null,
  selectedAgentAchorEl: null,
  error: null,
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    // START LOADING
    startFetching(state) {
      state.loading = true;
    },
    createAgentSuccess(state, action) {
      state.agents.push(action.payload);
      state.loading = false;
    },
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    fetchAgentsSuccess(state, action) {
      state.loading = false;
      state.agents = action.payload.agents;
    },
    updateAgentRunSuccess(state, action) {
      const agentRun = action.payload as AgentRunLogic;
      // update agent run
      state.agentsRuns[agentRun.agentId as number] = state.agentsRuns[
        agentRun.agentId as number
      ].map((run) => {
        if (run.id === agentRun.id) {
          return agentRun;
        }
        return run;
      });
    },
    setKnowledgeBaseOrAiFlowToView(state, action) {
      state.knowledgeBaseOrAiFlowToView = action.payload;
    },
    createAgentRunSuccess(state, action) {
      const agentRun = action.payload as AgentRunLogic;
      if (!state.agentsRuns[agentRun.agentId as number]) {
        state.agentsRuns[agentRun.agentId as number] = [];
      }
      state.agentsRuns[agentRun.agentId as number].push(agentRun);
      state.creatingAgentRun = false;
    },
    fetchAgentsRunsSuccess(state, action) {
      state.agentsRuns = action.payload;
    },
    activatingAgent(state, action) {
      state.activatingAgent = action.payload;
    },
    // activate agent success with agent id as payload
    activateAgentSuccess(state, action) {
      state.agents = state.agents.map((agent) => {
        if (agent.id === action.payload) {
          agent.draft = false;
        }
        return agent;
      });
      state.activatingAgent = false;
    },
    setSelectedAgent(state, action) {
      state.selectedAgent = action.payload;
      const agent = state.agents.find((a) => a.id === action.payload.id);
      if (agent) {
        const agentIndex = state.agents.indexOf(agent);
        state.agents[agentIndex] = action.payload;
      }
      state.loaded = true;
    },
    setHoveredAgentFieldOrder(state, action) {
      state.hoveredAgentFieldOrder = action.payload;
    },
    setSelectedAgentField(state, action) {
      state.selectedAgentField = action.payload;
    },
    setSidebarPopoverOpen(state, action) {
      state.sidebarPopoverOpen = action.payload;
    },
    setSelectedAgentAnchorEl(state, action) {
      state.selectedAgentAchorEl = action.payload;
    },
    // addAgentFieldSuccess(state, action) {
    //   if (!state.selectedAgent) {
    //     return;
    //   }
    //   if (!state.selectedAgent?.agentFields) {
    //     state.selectedAgent.agentFields = [];
    //   }
    //   state.selectedAgent?.agentFields?.push(action.payload);
    //   // update the agent that is in agents
    //   const agentIndex = state.agents.findIndex((a) => a.id === state.selectedAgent?.id);
    //   if (agentIndex !== -1) {
    //     state.agents[agentIndex] = state.selectedAgent;
    //   }
    //   state.loading = false;
    // },
    deleteAgentSuccess(state, action) {
      state.agents = state.agents.filter((agent: any) => agent.id !== action.payload.agentId);
    },
    deleteAgentsSuccess(state, action) {
      state.agents = state.agents.filter(
        (agent: any) => !action.payload.agentsId.includes(agent.id)
      );
    },
    // deleteAgentFieldSuccess(state, action) {
    //   if (!state.selectedAgent) {
    //     return;
    //   }
    //   state.selectedAgent.agentFields = state.selectedAgent.agentFields?.filter(
    //     (field) => field.id !== action.payload.agentFieldId
    //   );
    //   // update the agent that is in agents
    //   const agentIndex = state.agents.findIndex((a) => a.id === state.selectedAgent?.id);
    //   if (agentIndex !== -1) {
    //     state.agents[agentIndex] = state.selectedAgent;
    //   }
    //   state.loading = false;
    // },
    setPublishingAgent(state, action) {
      state.publishingAgent = action.payload;
    },
    publishAgentSuccess(state, action) {
      state.publishingAgent = false;
      if (state.selectedAgent) {
        state.selectedAgent.published = true;
        const agent = state.agents.find((a) => a.id === state.selectedAgent?.id);
        if (agent) {
          const agentIndex = state.agents.indexOf(agent);
          state.agents[agentIndex] = state.selectedAgent;
        }
      }
    },
    // updateAgentFieldSuccess(state, action) {
    //   if (!state.selectedAgent) {
    //     return;
    //   }
    //   const agentField = action.payload.agentField;

    //   if (action.payload.setSelectedField) {
    //     state.selectedAgentField = agentField;
    //   }

    //   state.selectedAgent.agentFields = state.selectedAgent.agentFields?.map((field) => {
    //     if (field.id === agentField.id) {
    //       return agentField;
    //     }
    //     return field;
    //   });
    //   // update the agent that is in agents
    //   const agentIndex = state.agents.findIndex((a) => a.id === state.selectedAgent?.id);
    //   if (agentIndex !== -1) {
    //     state.agents[agentIndex] = state.selectedAgent;
    //   }
    //   state.loading = false;
    // },
    // updateSomeAgentFieldsSuccess(state, action) {
    //   if (!state.selectedAgent) {
    //     return;
    //   }
    //   state.selectedAgent.agentFields = state.selectedAgent.agentFields?.map((field) => {
    //     const updatedField = action.payload.find((f: any) => f.id === field.id);
    //     if (updatedField) {
    //       return updatedField;
    //     }
    //     return field;
    //   });
    //   // update the agent that is in agents
    //   const agentIndex = state.agents.findIndex((a) => a.id === state.selectedAgent?.id);
    //   if (agentIndex !== -1) {
    //     state.agents[agentIndex] = state.selectedAgent;
    //   }
    //   state.loading = false;
    // },
    setCreatingAgentRun(state, action) {
      state.creatingAgentRun = action.payload;
    },
    // updateAgentFieldsSuccess(state, action) {
    //   if (!state.selectedAgent) {
    //     return;
    //   }
    //   state.selectedAgent.agentFields = action.payload;
    //   // update the agent that is in agents
    //   const agentIndex = state.agents.findIndex((a) => a.id === state.selectedAgent?.id);
    //   if (agentIndex !== -1) {
    //     state.agents[agentIndex] = state.selectedAgent;
    //   }
    //   state.loading = false;
    // },
    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const {
  setSelectedAgent,
  updateAgentRunSuccess,
  setKnowledgeBaseOrAiFlowToView,
  setHoveredAgentFieldOrder,
  setSelectedAgentField,
  setActiveStep,
  setSelectedAgentAnchorEl,
  setSidebarPopoverOpen,
} = slice.actions;

// ----------------------------------------------------------------------

export function fetchAgents() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      dispatch(slice.actions.startFetching());
      const agentApi = new AgentApi();
      const agents = await agentApi.apiAgentAgentsGet();
      dispatch(slice.actions.fetchAgentsSuccess({ agents: agents.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createAgent(newAgent: AgentLogic, navigate: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startFetching());
      const agentApi = new AgentApi();
      const createdAgent = await agentApi.apiAgentCreateAgentPost(newAgent);
      dispatch(slice.actions.createAgentSuccess(createdAgent.data));
      dispatch(slice.actions.setSelectedAgent(createdAgent.data));
      navigate(`/ai-agents/setup/${createdAgent.data.id}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateAgent(agent: AgentLogic, updateLocal: boolean = true) {
  return async (dispatch: Dispatch) => {
    try {
      //  dispatch(slice.actions.startFetching());
      const agentApi = new AgentApi();
      if (updateLocal) {
        dispatch(slice.actions.setSelectedAgent(agent));
      }
      await agentApi.apiAgentUpdateAgentPut(agent);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAgent(agentId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const agentApi = new AgentApi();
      await agentApi.apiAgentDeleteAgentIdDelete(agentId);
      enqueueSnackbar('Agent was deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deleteAgentSuccess({ agentId }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteAgents(agentsId: string[], provider: string, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      const emailProviderApi = new EmailProviderApi();
      // const response = await emailProviderApi.apiEmailProviderAgentsDelete({
      //   providerName: provider,
      //   agentsId,
      // });

      enqueueSnackbar('Agents were deleted succesfully', { variant: 'success' });
      dispatch(slice.actions.deleteAgentsSuccess({ agentsId, provider }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function activateAgent(agentId: number, enqueueSnackbar: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.activatingAgent(agentId));
      const agentApi = new AgentApi();
      await agentApi.apiAgentActivateAgentIdPut(agentId);
      dispatch(slice.actions.activateAgentSuccess(agentId));
      enqueueSnackbar('The agent was successfully activated and is now ready for use.', {
        variant: 'success',
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function publishAgent(
  agentId: number,
  agentName: string,
  redeploy: boolean,
  enqueueSnackbar: any
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.setPublishingAgent(true));
      const agentApi = new AgentApi();
      await agentApi.apiAgentPublishAgentIdPut(agentId);
      dispatch(slice.actions.publishAgentSuccess(agentId));
      enqueueSnackbar(`The agent was successfully ${redeploy ? 'redeployed' : 'deployed'}`, {
        variant: 'success',
      });
      if (!redeploy) {
        window.open(`https://${agentName}.kuverto.com`, '_blank');
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.setPublishingAgent(false));
      enqueueSnackbar('Agent with this name already exists, change the name and try again.', {
        variant: 'error',
      });
    }
  };
}
// export function createAgentRun(agentRun: AgentRunLogic) {
//   return async (dispatch: Dispatch) => {
//     try {
//       dispatch(slice.actions.setCreatingAgentRun(true));
//       const agentApi = new AgentApi();
//       const createdAgentRun = await agentApi.apiAgentCreateAgentRunPost(agentRun);
//       dispatch(slice.actions.createAgentRunSuccess(createdAgentRun.data.agentRun));
//       dispatch(addAiFlowRun(createdAgentRun.data.aiFlowRun));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function fetchAgentsRuns() {
  return async (dispatch: Dispatch) => {
    try {
      const agentApi = new AgentApi();
      const agentRuns = await agentApi.apiAgentAgentsRunsGet();
      dispatch(slice.actions.fetchAgentsRunsSuccess(agentRuns.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createAiAgentFromTemplate(template: AiAgentTemplate,navigate: any, onClose: any) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startFetching());
      const agentApi = new AgentApi();
      const createdAgent = await agentApi.apiAgentCreateAgentFromTemplatePost(template);
      dispatch(slice.actions.createAgentSuccess(createdAgent.data));
      dispatch(slice.actions.setSelectedAgent(createdAgent.data));
      onClose();
      navigate(`/ai-agents/setup/${createdAgent.data.id}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      onClose();
    }
  };
}