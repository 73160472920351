import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import { AnalyticsApi } from 'src/api';
import { IAnalyticsState } from 'src/@types/analytics';

// ----------------------------------------------------------------------

const initialState: IAnalyticsState = {
  userSessionAnalytics: [],
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // START LOADING
    startFetching(state) {
      state.loading = true;
    },
    fetchUserSessionsAnalyticsSuccess(state, action) {
      state.userSessionAnalytics = action.payload;
      state.loading = false;
      state.loaded = true;
    },
  },
});

// Reducer
export default slice.reducer;

// export const {} = slice.actions;

// ----------------------------------------------------------------------

export function fetchUserSessionsAnalytics() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startFetching());
    try {
      dispatch(slice.actions.startFetching());
      const analyticsApi = new AnalyticsApi();
      const userSessionAnalyticsResponse = await analyticsApi.apiAnalyticsUserSessionAnalyticsGet();
      dispatch(slice.actions.fetchUserSessionsAnalyticsSuccess(userSessionAnalyticsResponse.data));
    } catch (error) {
      console.error('fetchUserSessionsAnalytics', error);
    }
  };
}
