import { createSlice, Dispatch } from '@reduxjs/toolkit';
// @types
import {
  AgentUsersInputsApi,
} from 'src/api';
import { IAgentUserInputState } from 'src/@types/agent-user-input';

// ----------------------------------------------------------------------

const initialState: IAgentUserInputState = {
  agentsUsersInputs: [],
  agentsUsers: [],
  loading: false,
  loaded: false,
};

const slice = createSlice({
  name: 'agents-users-inputs',
  initialState,
  reducers: {
    // START LOADING
    startFetching(state) {
      state.loading = true;
    },
    getAgentsUsersInputsSuccess(state, action) {
      state.agentsUsersInputs = action.payload;
      state.loading = false;
    },
    getAgentsUsersSuccess(state, action) {
      state.agentsUsers = action.payload;
      state.loading = false;
    },
  },
});

// Reducer
export default slice.reducer;

export const { startFetching, getAgentsUsersInputsSuccess, getAgentsUsersSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function fetchAgentsUsers() {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(slice.actions.startFetching());
      const agentsUsersInputs = new AgentUsersInputsApi();
      const agentsUsers = await agentsUsersInputs.apiAgentUsersInputsAgentsUsersGet();
      dispatch(slice.actions.getAgentsUsersSuccess(agentsUsers.data));
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchAgentsUsersInputs() {
    return async (dispatch: Dispatch) => {
      try {
        dispatch(slice.actions.startFetching());
        const agentsUsersInputs = new AgentUsersInputsApi();
        const agentsUsers = await agentsUsersInputs.apiAgentUsersInputsAgentsUsersInputsGet();
        dispatch(slice.actions.getAgentsUsersInputsSuccess(agentsUsers.data));
      } catch (error) {
        console.error(error);
      }
    };
  }
  
