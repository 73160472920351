import { useEffect, useState, useCallback } from 'react';
// @mui
import {
  Stack,
  Dialog,
  Button,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material';
import { Upload } from 'src/components/upload';
import Iconify from 'src/components/iconify';
import { useDispatch, useSelector } from 'src/redux/store';
import { FilesApi, KnowledgeBaseFileLogic, UploadedFileLogic } from 'src/api';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBoolean } from 'src/hooks/use-boolean';
import {
  updateAiFlowItem,
  updateSelectedAiFlowItem,
  updateTempSelectedAiFlowItem,
} from 'src/redux/slices/aiflows';
import {
  AddKnowledgeBaseFiles,
  setSelectedKnowledgeBaseFile,
} from 'src/redux/slices/knowledgebases';
import { useSnackbar } from 'src/components/snackbar';
// components

// ----------------------------------------------------------------------

interface Props extends DialogProps {
  title?: string;
  single?: boolean;
  knowledgeBase?: boolean;
  open: boolean;
  onClose: VoidFunction;
}

export default function FilesUploadDialog({
  title = 'Upload Files',
  open,
  single,
  knowledgeBase,
  onClose,
  //
  ...other
}: Props) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState<(File | string)[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const uploading = useBoolean(false);
  const selectedItem = useSelector((state) => state.aiFlows.selectedAiFlowItem);
  const filesMap = useSelector((state) => state.upload.filesMap);
  const addingToVectorStore = useSelector((state) => state.knowledgeBase.addingToVectorStore);
  const selectedKnowledgeBase = useSelector((state) => state.knowledgeBase.selectedKnowledgeBase);
  const selectedKnowledgeBaseFile = useSelector(
    (state) => state.knowledgeBase.selectedKnowledgeBaseFile
  );
  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      let hasLargeFile = false;
      acceptedFiles.forEach((file) => {
        if (file.size > 50 * 1024 * 1024) {
          enqueueSnackbar('File size should be less than 50MB', { variant: 'error' });
          hasLargeFile = true;
        }
      });
      if (hasLargeFile) {
        return;
      }
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles([...files, ...newFiles]);
    },
    [files, enqueueSnackbar]
  );

  const handleUpload = async () => {
    try {
      uploading.onTrue();
      // const fileRequests: UploadFile[] = [];
      const formData = new FormData();
      const fileProcessingPromises = files.map(async (file) => {
        const { name, preview } = file as any;
        const fileName = filesMap[preview];

        const fileContent = (await readFileContent(file)) as any;
        const nameStr = name as string;
        formData.append('files', file as File, nameStr);
        // fileRequests.push({
        //   fileName: nameStr,
        //   fileContent,
        //   filePath: `${selectedItem?.id}/${nameStr}`,
        // });
      });
      await Promise.all(fileProcessingPromises);

      files.forEach((file) => {
        formData.append('files', file as File, file as string);
      });
      const filesApi = new FilesApi();
      let response;
      if (knowledgeBase) {
        response = await filesApi.apiFilesUploadPost(
          'knowledge-bases',
          files as File[],
          selectedKnowledgeBase?.id?.toString() as string
        );
      } else {
        response = await filesApi.apiFilesUploadPost(
          'inputs',
          files as File[],
          selectedItem?.id?.toString() as string
        );
      }
      if (response && response.data) {
        const uploadedFilesNames = response.data.map((file: UploadedFileLogic) => file.fileName);
        if (knowledgeBase) {
          const knowledgeBaseFiles = uploadedFilesNames.map((fileName) => ({
            fileName,
            knowledgeBaseId: selectedKnowledgeBase?.id,
            name: fileName,
          })) as KnowledgeBaseFileLogic[];
          dispatch(AddKnowledgeBaseFiles(knowledgeBaseFiles, enqueueSnackbar, onClose));
          // dispatch(
          //   setSelectedKnowledgeBaseFile({
          //     ...selectedKnowledgeBaseFile,
          //     fileName: uploadedFilesNames[0],
          //   })
          // );
        } else {
          const selectedItemUploadedFilesNames =
            selectedItem?.inputData?.actionInputData?.inputData?.uploadedFilesNames;
          const allUploadedFilesNames = [
            ...(selectedItemUploadedFilesNames || []),
            ...uploadedFilesNames,
          ];
          const updatedItem = {
            ...selectedItem,
            inputData: {
              ...selectedItem?.inputData,
              actionInputData: {
                ...selectedItem?.inputData?.actionInputData,
                inputData: {
                  ...selectedItem?.inputData?.actionInputData?.inputData,
                  uploadedFilesNames: allUploadedFilesNames,
                },
              },
            },
          };
          dispatch(updateTempSelectedAiFlowItem(updatedItem));
          onClose();
        }
      }
    } catch (e) {
      // console.log(e);
    } finally {
      uploading.onFalse();
    }
  };

  const readFileContent = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        resolve(event.target.result);
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsArrayBuffer(file);
    });

  // const fileToImage = async (fileContent: string) => {
  //   const templateApi = new TemplateApi();
  //   const base64Response = await templateApi.apiTemplateHtmlToBase64ImgPost(fileContent);
  //   const imageUrl = `data:image/png;base64,${base64Response.data}`;
  //   return imageUrl;
  // };

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  return (
    <Dialog
      sx={{
        zIndex: 2400,
      }}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      {...other}
    >
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>
        {' '}
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <span>Upload {single ? 'File' : 'Files'}</span>
          <IconButton onClick={onClose}>
            <Iconify icon="material-symbols:close" width={20} height={20} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
        <Upload multiple files={files} onDrop={handleDrop} onRemove={handleRemoveFile} />
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          alignContent: 'space-between',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        {/* <FormGroup>
          <FormControlLabel
            control={<Checkbox onChange={(e) => setSaveImportedTemplates(e.target.checked)} />}
            checked={saveImportedTemplates}
            label="Save Imported Templates"
          />
        </FormGroup> */}
        <Stack direction="row" spacing={1}>
          <LoadingButton
            type="submit"
            disabled={(single && files.length > 1) || !files.length}
            variant="contained"
            startIcon={<Iconify icon="eva:cloud-upload-fill" />}
            loading={uploading.value || addingToVectorStore}
            onClick={handleUpload}
          >
            Upload
          </LoadingButton>
          {/* <Button
          variant="contained"
          startIcon={<Iconify icon="eva:cloud-upload-fill" />}
          onClick={handleUpload}
        >
          Import
        </Button> */}

          {!!files.length && (
            <Button
              disabled={uploading.value}
              variant="outlined"
              color="inherit"
              onClick={handleRemoveAllFiles}
            >
              Remove all
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
